import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Heading,
  Select,
  Text,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { orderApi } from "services/order-api/order-api";
import { formattedDate } from "utils/date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import InputField from "components/fields/InputField";
import { FinalOrderInputSchema, STATUS } from "models/orders";
import { formatPrice } from "utils/utils";
import { EmailInput, FinalOrderInput } from "API";
import CustomCard from "components/card/Card";
import { v4 as uuidv4 } from "uuid";
import { htlmOrder } from "prototypes/emails/order";
import { emailApi } from "services/send-email-api/send-email-api";
import React from "react";
interface Descritpion {
  ita: string;
  en: string;
}

export const Order = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [estimateDate, setEstimateDate] = useState<string | undefined>(
    undefined
  );
  const [info, setInfo] = useState<Descritpion>({
    ita: "",
    en: "",
  });
  const [status, setStatus] = useState<STATUS>("ARRIVED");
  const [paymentStatus, setPaymentStatus] = useState<string>("NOT_PAID");
  const [shippinngCost, setShippingCost] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const {
    data: order,
    isLoading,
    isError,
  } = useQuery(
    ["getOrderByOrderId", orderId],
    () => orderApi.getOrder({ orderId: orderId || "" }),
    {
      enabled: !!orderId,
      onSuccess(data) {
        const estimateDate = data.estimateDate
          ? formattedDate(data.estimateDate)
          : undefined;
        setEstimateDate(estimateDate);

        setInfo({
          ita: data.info.ita,
          en: data.info.en,
        });

        setStatus(data.status);
        setPaymentStatus(data.payment.status);
        setShippingCost(data.payment.totalShipping);
        setTotalPrice(data.payment.totalPrice);
      },
    }
  );

  const { mutate: mutateUpdateOrder, isLoading: mutateIsLoading } = useMutation(
    ["updateOrder", orderId],
    async () => {
      if (!!order) {
        const tracking = order.tracking;
        // if order status is changed, update tracking add new tracking
        if (order.status !== status) {
          tracking.push({
            status,
            update: new Date().toISOString(),
            __typename: "Tracking",
          });
        }
        const finalOrder: FinalOrderInput = {
          ...order,
          ...tracking,
          status,
          estimateDate: estimateDate || "",
          info,
          payment: {
            ...order.payment,
            status: paymentStatus,
            totalShipping: shippinngCost,
            totalPrice,
          },
        };
        const finalOrderInput = FinalOrderInputSchema.parse(finalOrder);
        const response = await orderApi.updateOrder({ order: finalOrderInput });
        return response;
      } else {
        toast({
          title: "An error occurred.",
          description: "Info missing.",
          status: "error",
        });
        throw new Error("Info missing.");
      }
    },
    {
      onSuccess: (response) => {
        toast({
          title: "Order created.",
          description: "Your order has been updated.",
          status: "success",
        });
        queryClient.invalidateQueries(["getOrderByOrderId", orderId]);
      },
      onError: (error) => {
        toast({
          title: "An error occurred.",
          description: "Unable to create the order.",
          status: "error",
        });
      },
    }
  );

  const { mutate: sendEmailMutate, isLoading: mutateSendEmailIsLoading } =
    useMutation(
      ["sendEmail", orderId],
      async () => {
        if (!!order) {
          const emailInput: EmailInput = {
            emailId: uuidv4(),
            destinations: [order.address.email],
            html: htlmOrder(order),
            text: "",
            subject: "Order updated",
            source: "sales@oleificiocuscuna.com",
          };

          const response = await emailApi.sendEmail({
            email: emailInput,
          });
          return response;
        } else {
          toast({
            title: "An error occurred.",
            description: "Info missing.",
            status: "error",
          });
          throw new Error("Info missing.");
        }
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });
          queryClient.invalidateQueries(["getOrderByOrderId", orderId]);
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  const {
    mutate: sendEmailToPayMutate,
    isLoading: mutateSendEmailToPayIsLoading,
  } = useMutation(
    ["sendEmail", orderId],
    async () => {
      if (!!order) {
        const emailInput: EmailInput = {
          emailId: uuidv4(),
          destinations: [order.address.email],
          html: htlmOrder(order, true),
          text: "",
          subject: "Order updated",
          source: "sales@oleificiocuscuna.com",
        };

        const response = await emailApi.sendEmail({
          email: emailInput,
        });
        return response;
      } else {
        toast({
          title: "An error occurred.",
          description: "Info missing.",
          status: "error",
        });
        throw new Error("Info missing.");
      }
    },
    {
      onSuccess: (response) => {
        toast({
          title: "Order created.",
          description: "Your order has been updated.",
          status: "success",
        });
        queryClient.invalidateQueries(["getOrderByOrderId", orderId]);
      },
      onError: (error) => {
        toast({
          title: "An error occurred.",
          description: "Unable to create the order.",
          status: "error",
        });
      },
    }
  );

  const { mutate: mutateUpdatHistoryOrder, isLoading: mutateHistoryIsLoading } =
    useMutation(
      ["updatHistoryOrder", orderId],
      async () => {
        if (!!order) {
          const tracking = order.tracking;
          // if order status is changed, update tracking add new tracking
          if (order.status !== status) {
            tracking.push({
              status,
              update: new Date().toISOString(),
              __typename: "Tracking",
            });
          }
          const finalOrder: FinalOrderInput = {
            ...order,
            ...tracking,
            status,
            estimateDate: estimateDate || "",
            info,
            payment: {
              ...order.payment,
              status: paymentStatus,
              totalShipping: shippinngCost,
              totalPrice,
            },
          };
          const finalOrderInput = FinalOrderInputSchema.parse(finalOrder);
          const response = await orderApi.updateHistoryOrder({
            order: finalOrderInput,
          });
          return response;
        } else {
          toast({
            title: "An error occurred.",
            description: "Info missing.",
            status: "error",
          });
          throw new Error("Info missing.");
        }
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });
          queryClient.invalidateQueries(["getOrderByOrderId", orderId]);
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  const { mutate: mutateDeleteProduct, isLoading: mutateDeleteIsLoading } =
    useMutation(
      ["deleteOrder", orderId],
      async () => {
        if (!order) {
          toast({
            title: "An error occurred.",
            description: "Unable to delete the product.",
            status: "error",
          });
          return;
        }
        const response = await orderApi.deleteOrder({
          orderId: order.orderId,
          userId: order.userId,
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });

          navigate(`/orders`, { replace: true });
          return response;
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  if (
    isLoading ||
    mutateIsLoading ||
    mutateDeleteIsLoading ||
    mutateHistoryIsLoading ||
    mutateSendEmailIsLoading ||
    mutateSendEmailToPayIsLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  const startDate = formattedDate(order.startDate);
  const endDate = order.endDate
    ? formattedDate(order.endDate)
    : "Not ended yet";

  const handleEstimateDateChange = (date: any) => {
    date.setHours(19);
    setEstimateDate(date.toISOString());
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Heading size="md">Order Details</Heading>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Stack spacing={4}>
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Heading size="md">Order</Heading>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Stack spacing={4}>
                      <Box>UserId: {order.userId}</Box>
                      <Box>Order Name: {order.orderName}</Box>
                      <Box>Customer: {order.address.recipient}</Box>
                      <Box>Address: {order.address.formattedAddress}</Box>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Heading size="md">Order Tracking</Heading>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Stack spacing={4}>
                      <Box>
                        Tracking:{" "}
                        <Box display="flex">
                          {order.tracking.map((track, index) => (
                            <CustomCard key={index}>{track.status} </CustomCard>
                          ))}
                        </Box>
                      </Box>
                      <Box>
                        Status:
                        <Select
                          value={status}
                          onChange={(e) => setStatus(e.target.value as STATUS)}
                        >
                          <option value="CREATED">Created</option>
                          <option value="ARRIVED">Arrived</option>
                          <option value="STARTED">Started</option>
                          <option value="READY">Ready</option>
                          <option value="DELIVERING">Delivering</option>
                          <option value="DELIVERED">Delivered</option>
                          <option value="CANCELED">Canceled</option>
                          <option value="REJECTED">Rejected</option>
                        </Select>
                      </Box>
                      <Box>Start Date: {startDate}</Box>
                      <Box>
                        Estimate Date:{" "}
                        <DatePicker
                          selected={
                            estimateDate ? new Date(estimateDate) : undefined
                          }
                          value={estimateDate}
                          onChange={(date: any) =>
                            handleEstimateDateChange(date)
                          }
                        />
                      </Box>
                      <Box>End Date: {endDate}</Box>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Heading size="md">Order Info</Heading>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Stack spacing={4}>
                      <InputField
                        id="info"
                        label="Ita"
                        extra={<></>}
                        placeholder="Info ita"
                        type="text"
                        mb="30px"
                        value={info.ita}
                        onChange={(e) =>
                          setInfo({ ...info, ita: e.target.value })
                        }
                      />

                      <InputField
                        id="info"
                        label="En"
                        extra={<></>}
                        placeholder="Info en"
                        type="text"
                        mb="30px"
                        value={info.en}
                        onChange={(e) =>
                          setInfo({ ...info, en: e.target.value })
                        }
                      />
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Heading size="md">Order Payment</Heading>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Stack spacing={4}>
                      <Box>Payment Method: {order.payment.method}</Box>
                      <Box>Payment Id: {order.payment.paymentId}</Box>
                      <Select
                        value={paymentStatus}
                        onChange={(e) =>
                          setPaymentStatus(e.target.value as STATUS)
                        }
                      >
                        <option value="NOT_PAID">NOT_PAID</option>
                        <option value="TO_PAY">TO_PAY</option>
                        <option value="PAID">PAID</option>
                      </Select>
                      <Box>Payment Total: {formatPrice(totalPrice)} €</Box>
                      <InputField
                        id="totalPrice"
                        label="Total Price"
                        extra={<></>}
                        placeholder="Total Price"
                        type="number"
                        mb="30px"
                        value={totalPrice.toString()}
                        onChange={(e) => setTotalPrice(Number(e.target.value))}
                      />
                      <Box>
                        Payment Shipping: {formatPrice(shippinngCost)} €
                      </Box>
                      <InputField
                        id="shippingCost"
                        label="Shipping Cost"
                        extra={<></>}
                        placeholder="Shipping Cost"
                        type="number"
                        mb="30px"
                        value={shippinngCost.toString()}
                        onChange={(e) => {
                          setShippingCost(Number(e.target.value));
                          setTotalPrice(
                            order.payment.totalProductsPrice +
                              Number(e.target.value)
                          );
                        }}
                      />
                      <Box>
                        Payment Total products price:{" "}
                        {formatPrice(order.payment.totalProductsPrice)} €
                      </Box>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Heading size="md">Order Products</Heading>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Stack spacing={4}>
                      {order.products.map((product, index) => (
                        <Box key={product.productId}>
                          <Heading size="md">Product {index + 1}</Heading>
                          <Box>Attributes: {product.attribute}</Box>
                          <Box>Certifications: {product.certifications}</Box>
                          <Box>Descritpion ita: {product.description.ita}</Box>
                          <Box>Descritpion en: {product.description.en}</Box>
                          <Box>
                            Discount:{" "}
                            {product.discount
                              ? `${product.discount}`
                              : "No discount"}
                          </Box>
                          <Box>Format box type: {product.format.boxType}</Box>
                          <Box>
                            Format box type quantity:{" "}
                            {product.format.boxTypeQuantity}
                          </Box>
                          <Box>
                            Format box weight: {product.format.boxWeight}
                          </Box>
                          <Box>
                            Format description ita:{" "}
                            {product.format.description.ita}
                          </Box>
                          <Box>
                            Format description en:{" "}
                            {product.format.description.en}
                          </Box>
                          <Box>
                            Format quantity: {product.format.formatQuantity}
                          </Box>
                          <Box>Format type: {product.format.formatType}</Box>
                          <Box>Format unit: {product.format.unit}</Box>
                          <Box>
                            Format unit Price: {product.format.unitPrice}
                          </Box>
                          <Box>Product is new: {product.isNew}</Box>
                          <Box>Product is soldout: {product.isSoldOut}</Box>
                          <Box>Product is this year: {product.isThisYear}</Box>
                          <Box>Line: {product.line}</Box>
                          <Box>Name ita: {product.name.ita}</Box>
                          <Box>Name en: {product.name.en}</Box>
                          <Box>Price: {product.price}</Box>
                          <Box>Product id: {product.productId}</Box>
                          <Box>Product type: {product.productType}</Box>
                          <Box>
                            Selecte Quantity: {product.selectedQuantity}
                          </Box>
                          <Box>
                            Size type:{" "}
                            {product.sizeType
                              ? `${product.productType}`
                              : "No size type"}
                          </Box>

                          <Divider border="3px solid" />
                        </Box>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Stack mt={2} justifyContent="space-around">
                <Button mt={10} onClick={() => mutateUpdateOrder()}>
                  <Text>UPDATE ORDER</Text>
                </Button>
                <Button onClick={() => mutateDeleteProduct()}>
                  <Text>DELETE ORDER</Text>
                </Button>
                <Button onClick={() => mutateUpdatHistoryOrder()}>
                  <Text>MOVE TO HISTORY</Text>
                </Button>
                <Button onClick={() => sendEmailMutate()}>
                  <Text>SEND EMAIL</Text>
                </Button>
                <Button onClick={() => sendEmailToPayMutate()}>
                  <Text>SEND EMAIL TO PAY</Text>
                </Button>
              </Stack>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Box>
        <div dangerouslySetInnerHTML={{ __html: htlmOrder(order) }} />
      </Box>
    </Box>
  );
};
