import { Auth, CognitoUser } from "@aws-amplify/auth";
import { ICredentials } from "@aws-amplify/core";
import { CognitoUserSession } from "amazon-cognito-identity-js";

import { parseError } from "../../utils/error";
import { isObjectEmpty } from "../../utils/objectEmpty";
import * as aws from "@aws-sdk/client-cognito-identity-provider";

const { REACT_APP_USER_POOL_ID_APP } = process.env;

export interface UserInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export enum Status {
  ALREADY_REGISTERED = "ALREADY_REGISTERED",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface ICognitoUser {
  cognitoUser?: CognitoUser | any;
  status?: Status;
}

export interface IUserInfo {
  credentials: ICredentials;
  info: any;
}

export interface UserAttributes {
  id: string;
  phone_number: string;
  given_name: string;
  family_name: string;
  email: string;
  "custom:role": string;
  "custom:newsletter": string;
}

export function transformUserAttributes(attributes: any[]): UserAttributes {
  const transformedAttributes: UserAttributes = {
    id: "",
    phone_number: "",
    given_name: "",
    family_name: "",
    email: "",
    "custom:role": "",
    "custom:newsletter": "",
  };

  for (const attribute of attributes) {
    switch (attribute.Name) {
      case "sub":
        transformedAttributes.id = attribute.Value;
        break;
      case "phone_number":
        transformedAttributes.phone_number = attribute.Value;
        break;
      case "given_name":
        transformedAttributes.given_name = attribute.Value;
        break;
      case "family_name":
        transformedAttributes.family_name = attribute.Value;
        break;
      case "email":
        transformedAttributes.email = attribute.Value;
        break;
      case "custom:role":
        transformedAttributes["custom:role"] = attribute.Value;
        break;
      case "custom:newsletter":
        transformedAttributes["custom:newsletter"] = attribute.Value;
        break;
    }

    if (!transformedAttributes["custom:role"]) {
      transformedAttributes["custom:role"] = "standard";
    }

    if (!transformedAttributes["custom:newsletter"]) {
      transformedAttributes["custom:newsletter"] = "false";
    }
  }

  return transformedAttributes;
}

interface Authentication {
  currentAuthenticatedUser: () => Promise<CognitoUser | any>;
  currentCredentials: () => Promise<ICredentials>;
  currentUser: () => Promise<any>;
  currentUserInfo: () => Promise<any>;
  logout: () => Promise<Status>;
  refreshSession: () => Promise<CognitoUserSession>;
  sendOtp: (otp: string) => Promise<Status>;
  signIn: (email: string, password: string) => Promise<ICognitoUser>;
  signUp: (user: UserInput) => Promise<ICognitoUser>;
  updateAttributes: (
    givenName: string,
    familyName: string,
    phoneNumber: string
  ) => Promise<Status>;
  listUsers: (userId: string) => Promise<UserAttributes>;
}

export class AuthApi implements Authentication {
  cognitoUser!: CognitoUser | null;

  signUp = async (user: UserInput): Promise<ICognitoUser> => {
    try {
      this.cognitoUser = null;
      const password = user.password;
      await Auth.signUp({
        password,
        username: user.email,
        attributes: {
          email: user.email,
          family_name: user.lastName,
          given_name: user.firstName,
          phone_number: user.phoneNumber,
        },
      });
      const signInResult: CognitoUser | any = await Auth.signIn(
        user.email,
        user.password
      );
      this.cognitoUser = signInResult;
      return { cognitoUser: signInResult, status: Status.SUCCESS };
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "UsernameExistsException") {
          console.error("User already registered. Skip to sign-in");
          return {
            cognitoUser: undefined,
            status: Status.ALREADY_REGISTERED,
          };
        }
        return { cognitoUser: undefined, status: Status.ERROR };
      }
      console.error("[authApi-signUp]: ", parseError(error));
      return { cognitoUser: undefined, status: Status.ERROR };
    }
  };

  signIn = async (email: string, password: string): Promise<ICognitoUser> => {
    this.cognitoUser = await Auth.signIn(email, password);
    if (!this.cognitoUser) {
      return { cognitoUser: undefined, status: Status.ERROR };
    }
    return { cognitoUser: this.cognitoUser, status: Status.SUCCESS };
  };

  sendOtp = async (otp: string): Promise<Status> => {
    try {
      if (this.cognitoUser) {
        await Auth.sendCustomChallengeAnswer(this.cognitoUser, otp); // doesn't throw if code is wrong
        await Auth.currentAuthenticatedUser();
        return Status.SUCCESS;
      } else {
        console.error("[authApi-sendOtp]: there is not cognito user");
        return Status.ERROR;
      }
    } catch (error) {
      console.error("[authApi-sendOtp]: ", parseError(error));
      return Status.ERROR;
    }
  };

  refreshSession = async (): Promise<CognitoUserSession> => {
    return Auth.currentSession();
  };

  currentAuthenticatedUser = async () => {
    return Auth.currentAuthenticatedUser();
  };

  currentUserInfo = async () => {
    return Auth.currentUserInfo();
  };

  currentCredentials = async () => {
    return Auth.currentCredentials();
  };

  currentUser = async () => {
    try {
      // refresh session if the user is logged
      await this.refreshSession();
      const cognitoUser = await this.currentAuthenticatedUser();
      this.cognitoUser = cognitoUser;
      const info = await this.currentUserInfo();
      if (info && !isObjectEmpty(info)) {
        const credentials = await this.currentCredentials();
        console.log("INFO_CREDENTIALS", info);
        console.log("CURRENT_CREDENTIALS", credentials);
        return { credentials, info };
      }
      throw new Error("[user] User not authenticated.");
    } catch (error) {
      console.error("[authApi-currentUser]: ", parseError(error));
      throw new Error("[user] User not authenticated.");
    }
  };

  updateAttributes = async (
    givenName: string,
    familyName: string,
    phoneNumber: string
  ) => {
    try {
      await Auth.updateUserAttributes(this.cognitoUser, {
        family_name: familyName,
        given_name: givenName,
        phone_number: phoneNumber,
      });
      return Status.SUCCESS;
    } catch (error) {
      console.error(
        "[auth-api] There was an error while updating user's attributes",
        parseError(error)
      );
      return Status.ERROR;
    }
  };

  logout = async () => {
    try {
      await Auth.signOut();
      return Status.SUCCESS;
    } catch (error: any) {
      console.log(parseError(error));
      return Status.ERROR;
    }
  };

  listUsers = async (userId: string) => {
    const credentials = await this.currentCredentials();
    console.log("LIST_USERS_CREDENTIALS", credentials);
    const client = new aws.CognitoIdentityProviderClient({
      region: "eu-west-1",
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });
    const command = new aws.AdminGetUserCommand({
      UserPoolId: REACT_APP_USER_POOL_ID_APP,
      Username: userId,
    });
    try {
      const response = await client.send(command);

      const userAttributes = transformUserAttributes(
        response.UserAttributes || []
      );
      console.log("USER_ATTRIBUTES", userAttributes);
      return userAttributes;
    } catch (error) {
      console.error("[auth-api] There was an error while listing users", error);
      throw new Error("There was an error while listing users");
    }
  };

  updateUserAttribute = async (userId: string) => {
    const credentials = await this.currentCredentials();
    console.log("LIST_USERS_CREDENTIALS", credentials);
    const client = new aws.CognitoIdentityProviderClient({
      region: "eu-west-1",
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });
    const command = new aws.AdminGetUserCommand({
      UserPoolId: REACT_APP_USER_POOL_ID_APP,
      Username: userId,
    });
    try {
      const response = await client.send(command);

      const userAttributes = transformUserAttributes(
        response.UserAttributes || []
      );

      const data = new aws.AdminUpdateUserAttributesCommand({
        UserAttributes: [
          {
            Name: "custom:role",
            Value:
              userAttributes["custom:role"] === "standard" ? "vip" : "standard",
          },
        ],
        UserPoolId: REACT_APP_USER_POOL_ID_APP,
        Username: userId,
      });

      const result = await client.send(data);

      console.log("RESULT", result);
      return result;
    } catch (error) {
      console.error(
        "[auth-api] There was an error while change role user",
        error
      );
      throw new Error("There was an error while change role user");
    }
  };

  listAllUsers = async () => {
    const credentials = await this.currentCredentials();
    console.log("LIST_USERS_CREDENTIALS", credentials);
    const client = new aws.CognitoIdentityProviderClient({
      region: "eu-west-1",
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });
    // list of all users
    const command = new aws.ListUsersCommand({
      UserPoolId: REACT_APP_USER_POOL_ID_APP,
    });
    try {
      const response = await client.send(command);

      console.log("RESPONSE", response);
      return response;
    } catch (error) {
      console.error("[auth-api] There was an error while listing users", error);
      throw new Error("There was an error while listing users");
    }
  };
}

export const authApi = new AuthApi();
