import { Storage } from "@aws-amplify/storage";

import { getAppError } from "./error";

export const uploadPhoto = async (
  image: string,
  fileName: string,
  path: string
) => {
  try {
    const fetchLocalImage = await fetch(image);

    const blob = await fetchLocalImage.blob();

    await Storage.put(`${fileName}.png`, blob, {
      contentType: "image/png",
      customPrefix: {
        public: path,
      },
    });
  } catch (error) {
    console.error(getAppError(error));
    throw new Error(getAppError(error));
  }
};

export const uploadAppJson = async (
  image: string,
  fileName: string,
  path: string
) => {
  try {
    const fetchLocalImage = await fetch(image);

    const blob = await fetchLocalImage.blob();

    await Storage.put(`${fileName}.json`, blob, {
      contentType: "application/json",
      customPrefix: {
        public: path,
      },
    });
  } catch (error) {
    console.error(getAppError(error));
    throw new Error(getAppError(error));
  }
};
