import { Box, Button, SimpleGrid, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import FFTable from "components/tables/FFTable";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ffApi } from "services/ff-api/ff-api";

interface FF {
  ff: string;
  ffId: string;
  users: number;
  button: string;
}

export const FFS = () => {
  const [ffs, setFFs] = useState<FF[]>([]);
  const navigate = useNavigate();
  const { isError, isLoading } = useQuery(["getFFs"], () => ffApi.getFFs(), {
    onSuccess(data) {
      data.forEach((ff) => {
        setFFs((prev) => [
          ...prev,
          {
            ff: ff.ff,
            ffId: ff.ffId,
            users: ff.users ? ff.users.length : 0,
            button: ff.ffId,
          },
        ]);
      });
    },
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Button mb={2} onClick={() => navigate("/ffs/new-ff")}>
        ADD
      </Button>
      <SimpleGrid
        mb="20px"
        // columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <FFTable tableData={ffs} />
      </SimpleGrid>
    </Box>
  );
};
