import { z } from "zod";

export const DescriptionSchema = z.object({
  __typename: z.literal("Description"),
  ita: z.string(),
  en: z.string(),
});

const FinalInfo = z.object({
  __typename: z.literal("FinalInfo"),
  description: DescriptionSchema,
  navigateTo: z.union([z.string(), z.null()]),
  priority: z.number(),
  title: DescriptionSchema,
  status: z.union([z.literal("DRAFT"), z.literal("LIVE")]),
});

export const FinalHomeCardSchema = z.object({
  __typename: z.literal("FinalHomeCard"),
  color: z.array(z.string()),
  homeCardId: z.string(),
  info: z.union([z.array(FinalInfo), z.null()]),
  name: DescriptionSchema,
  status: z.union([z.literal("DRAFT"), z.literal("LIVE")]),
  priority: z.number(),
  type: z.union([
    z.literal("order"),
    z.literal("newProduct"),
    z.literal("discount"),
    z.literal("interess"),
    z.literal("mostPurchase"),
    z.literal("info"),
  ]),
  users: z.union([z.array(z.string()), z.null()]),
});

export const DescriptionInputSchema = z.object({
  ita: z.string(),
  en: z.string(),
});

const FinalInfoInput = z.object({
  description: DescriptionInputSchema,
  navigateTo: z.union([z.string(), z.null()]),
  priority: z.number(),
  title: DescriptionInputSchema,
  status: z.union([z.literal("DRAFT"), z.literal("LIVE")]),
});

export const FinalHomeCardInputSchema = z.object({
  color: z.array(z.string()),
  homeCardId: z.string(),
  info: z.union([z.array(FinalInfoInput), z.null()]),
  name: DescriptionInputSchema,
  status: z.union([z.literal("DRAFT"), z.literal("LIVE")]),
  priority: z.number(),
  type: z.union([
    z.literal("order"),
    z.literal("newProduct"),
    z.literal("discount"),
    z.literal("interess"),
    z.literal("mostPurchase"),
    z.literal("info"),
  ]),
  users: z.union([z.array(z.string()), z.null()]),
});
