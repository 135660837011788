import { Box, SimpleGrid, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import BoxTable from "components/tables/BoxTable";
import { useState } from "react";

import { boxApi } from "services/boxes-api/box-api";

interface BoxObj {
  title: string;
  bannerId: string;
  status: string;
  button: string;
}

export const Boxes = () => {
  const [boxes, setBoxes] = useState<BoxObj[]>([]);
  const { isError, isLoading } = useQuery(
    ["getBoxes"],
    () => boxApi.getBoxes(),
    {
      onSuccess(data) {
        data.forEach((banner) => {
          setBoxes((prev) => [
            ...prev,
            {
              title: banner.title.ita,
              bannerId: banner.bannerId,
              status: banner.status,
              button: banner.boxId,
            },
          ]);
        });
      },
    }
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        // columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <BoxTable tableData={boxes} />
      </SimpleGrid>
    </Box>
  );
};
