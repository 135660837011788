import { Navigate } from "react-router-dom";
import { authApi } from "../services/auth-api/auth-api";
import { Box, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

export const CheckRoutes = () => {
  console.log("CheckRoutes");

  const currentUser = useQuery(["currentUser"], () => authApi.currentUser(), {
    retry: 1,
  });

  if (currentUser.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (currentUser.isError) {
    return <Navigate to={"/login"} replace={true} />;
  }

  return <Navigate to={"/home"} replace={true} />;
};
