import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdPeople,
  MdReorder,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import { IoMdCart } from "react-icons/io";
import { IoCard, IoFlagSharp } from "react-icons/io5";
import { BiBox, BiFlag } from "react-icons/bi";
import { RiNewspaperLine } from "react-icons/ri";
import { FaFileInvoice } from "react-icons/fa";

// Auth Imports

const routes = [
  {
    name: "Home",
    layout: "/home",
    path: "",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Boxes",
    layout: "/boxes",
    path: "",
    icon: <Icon as={BiBox} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Newsletter",
    layout: "/newsletter",
    path: "",
    icon: (
      <Icon as={RiNewspaperLine} width="20px" height="20px" color="inherit" />
    ),
    component: MainDashboard,
  },
  {
    name: "FF",
    layout: "/ffs",
    path: "",
    icon: <Icon as={BiFlag} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "App Mode",
    layout: "/app-mode",
    path: "",
    icon: <Icon as={IoFlagSharp} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },
  {
    name: "Banners",
    layout: "/banners",
    path: "",
    icon: <Icon as={IoCard} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },
  {
    name: "Customers",
    layout: "/customers",
    path: "",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },
  {
    name: "Catalog",
    layout: "/catalog",
    path: "",
    icon: <Icon as={IoMdCart} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },
  {
    name: "Orders",
    layout: "/orders",
    path: "",
    icon: <Icon as={MdReorder} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },
  {
    name: "Invoicies",
    layout: "/invoicies",
    path: "",
    icon: (
      <Icon as={FaFileInvoice} width="20px" height="20px" color="inherit" />
    ),
    component: RTL,
  },
  {
    name: "Jobs",
    layout: "/jobs",
    path: "",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/data-tables",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "",
    component: DataTables,
  },
  {
    name: "Profile",
    layout: "/profile",
    path: "",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
];

export default routes;
