import { Box, Spinner } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CheckRoutes } from "./CheckRoutes";
import { AuthRoutes } from "./AuthRoutes";
import { useQuery } from "@tanstack/react-query";
import { authApi } from "services/auth-api/auth-api";
import SignIn from "views/auth/signIn";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

const LoginGuard: React.FC<{ children?: ReactNode }> = ({ children }) => {
  console.log("LoginGuard");
  const currentUser = useQuery(["currentUser"], () => authApi.currentUser(), {
    retry: 1,
  });

  if (currentUser.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (currentUser.isError) {
    return <Navigate to={"/login"} replace={true} />;
  }

  return <>{children}</>;
};

const RedirectGuard: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const currentUser = useQuery(["currentUser"], () => authApi.currentUser(), {
    retry: 1,
  });
  console.log("RedirectGuard");

  if (currentUser.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (currentUser.isError) {
    return <>{children}</>;
  }

  return <AuthRoutes />;
};

export const AppRoutes = () => (
  <React.Suspense
    fallback={
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    }
  >
    <Routes>
      <Route path="/" element={<CheckRoutes />} />
      <Route
        path="/login"
        element={
          <RedirectGuard>
            <SignIn />
          </RedirectGuard>
        }
      />

      <Route
        path="/*"
        element={
          <LoginGuard>
            <AuthRoutes />
          </LoginGuard>
        }
      />
    </Routes>
    <FixedPlugin />
  </React.Suspense>
);
