import React from "react";
import { Box, Button, Heading, Spinner, useToast } from "@chakra-ui/react";

import { useNavigate, useParams } from "react-router-dom";
import { FF as FFData } from "../../API";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import InputField from "components/fields/InputField";
import { ffApi } from "services/ff-api/ff-api";
import { FFInputSchema } from "models/ff/ff";
import { v4 as uuidv4 } from "uuid";

const deaulftFF: FFData = {
  __typename: "FF",
  ffId: uuidv4(),
  ff: "",
  users: [],
};

export const NewFF = () => {
  const { ffId } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const [ff, setFF] = React.useState<FFData>(deaulftFF);

  const { mutate: mutateUpdateFF, isLoading: mutateFFIsLoading } = useMutation(
    ["updateFF", ffId],
    async () => {
      const ffInput = FFInputSchema.parse(ff);

      const response = await ffApi.updateFF(ffInput);
      return response;
    },
    {
      onSuccess: (response) => {
        toast({
          title: "Order created.",
          description: "Your order has been updated.",
          status: "success",
        });
        queryClient.invalidateQueries(["getFFByFFId", response.ffId]);
        navigate(`/ffs/${response.ffId}`, { replace: true });
      },
      onError: (error) => {
        toast({
          title: "An error occurred.",
          description: "Unable to create the order.",
          status: "error",
        });
      },
    }
  );

  if (mutateFFIsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box>
        <Heading as="h1" fontSize="3xl" mb={4}>
          {ff.ffId}
        </Heading>
        <Box>
          <InputField
            id="name"
            label="Name"
            extra={<></>}
            placeholder="Name"
            type="text"
            mb="30px"
            value={ff.ff}
            onChange={(e) => {
              setFF({ ...ff, ff: e.target.value });
            }}
          />
          <Box mb={2}>
            users:{" "}
            {ff.users && ff.users.map((attribute) => attribute).join(", ")}
          </Box>
          <InputField
            id="users"
            label="Users"
            extra={<></>}
            placeholder="Users"
            type="text"
            mb="30px"
            value={ff.users ? ff.users.join(", ") : ""}
            onChange={(e) =>
              setFF((prev) => ({
                ...prev,
                users: e.target.value.split(",").map((u) => u.trim()),
              }))
            }
          />
          <Button onClick={() => mutateUpdateFF()}>UPDATE FF</Button>
        </Box>
      </Box>
    </Box>
  );
};
