import {
  Box,
  Button,
  HStack,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { uploadAppJson } from "utils/upload-photo";

interface App {
  maintenance: boolean;
}

export const appDefaultData: App = {
  maintenance: false,
};

const { REACT_APP_CDN } = process.env;

export const AppMode = () => {
  const toast = useToast();
  const [appConfig, setAppConfing] = useState<App>(appDefaultData);
  const [jsonLoading, setJsonLoading] = useState(false);

  const { isLoading, isError } = useQuery(
    ["appJson"],
    () => fetch(`${REACT_APP_CDN}/app.json`).then((res) => res.json()),
    {
      onSuccess: (data) => {
        setAppConfing(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  if (isLoading || jsonLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  const handleJsonUpload = async () => {
    setJsonLoading(true);

    try {
      const jsonContent = JSON.stringify(appConfig);
      // Assuming path and fileName are defined elsewhere in your component
      const blob = new Blob([jsonContent], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const path = "";

      await uploadAppJson(url, "app", path);
      console.log("Json uploaded successfully!");
      toast({
        title: "Image uploaded successfully.",
        description: "Your image has been uploaded.",
        status: "success",
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to upload the image.",
        status: "error",
      });
    }
    setJsonLoading(false);
  };

  // shoe name property and value
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Button mb={2} onClick={() => handleJsonUpload()}>
        Update
      </Button>
      <HStack>
        <Text>Maintenance: </Text>
        <Select
          value={appConfig.maintenance ? "true" : "false"}
          onChange={(e) => {
            setAppConfing({
              ...appConfig,
              maintenance: e.target.value === "true",
            });
          }}
        >
          <option value="true">true</option>
          <option value="false">false</option>
        </Select>
      </HStack>
    </Box>
  );
};
