import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Text,
  Heading,
  Select,
  Spinner,
  Stack,
  useToast,
  Input,
  HStack,
  SimpleGrid,
  Badge,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FinalProduct, Product } from "API";
import InputField from "components/fields/InputField";
import { FinalProductInputSchema, LINE } from "models/products";
import { v4 as uuidv4 } from "uuid";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { catalogApi } from "services/catalog-api/catalog-api";
import { calculatePrice, formatPrice } from "utils/utils";
import { uploadPhoto } from "utils/upload-photo";
import { OrderModal } from "components/order-card/OrderModal";
import OrderProductInfo from "components/order-card/OrderProductInfo";
import { AsyncImage } from "components/async-image/AsyncImage";

const { REACT_APP_CDN } = process.env;

export const ProductPage = () => {
  const { productId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const [lang, setLang] = useState<"ita" | "en">("ita");
  const [product, setProduct] = useState<FinalProduct | undefined>(undefined);
  const [imageLoading, setImageLoading] = useState(false);

  const { isLoading, isError } = useQuery(
    ["getProductByProductId", productId],
    () => catalogApi.getProductByProductId({ productId: productId || "" }),
    {
      enabled: !!productId,
      onSuccess(data) {
        setProduct(data);
      },
    }
  );

  const { mutate: mutateUpdateProduct, isLoading: mutateIsLoading } =
    useMutation(
      ["updateProduct", productId],
      async () => {
        const finalProductInput = FinalProductInputSchema.parse(product);
        const discount =
          finalProductInput.discount &&
          finalProductInput.discount.discountPrice === 0
            ? null
            : finalProductInput.discount;
        const response = await catalogApi.updateProduct({
          product: {
            ...finalProductInput,
            discount,
          },
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });
          queryClient.invalidateQueries(["getProductByProductId", productId]);
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  const { mutate: mutateCloneProduct, isLoading: mutateCloneIsLoading } =
    useMutation(
      ["updateProduct", productId],
      async () => {
        const finalProductInput = FinalProductInputSchema.parse({
          ...product,
          productStatus: "DRAFT",
          productId: uuidv4(),
        });
        const response = await catalogApi.updateProduct({
          product: finalProductInput,
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });
          // navigate and replace the current product with the new one
          queryClient.invalidateQueries([
            "getProductByProductId",
            response.productId,
          ]);
          navigate(`/catalog/${response.productId}`, { replace: true });
          return response;
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  const { mutate: mutateDeleteProduct, isLoading: mutateDeleteIsLoading } =
    useMutation(
      ["deleteProduct", productId],
      async () => {
        if (!product) {
          toast({
            title: "An error occurred.",
            description: "Unable to delete the product.",
            status: "error",
          });
          return;
        }
        const response = await catalogApi.deleteProduct({
          productId: product.productId,
          roleType: product.roleType,
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });

          navigate(`/catalog`, { replace: true });
          return response;
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  if (
    isLoading ||
    !product ||
    mutateIsLoading ||
    mutateCloneIsLoading ||
    mutateDeleteIsLoading ||
    imageLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  const handleImageUpload = async (event: any) => {
    setImageLoading(true);
    const file = event.target.files[0]; // Assuming only one file is selected
    const imageUrl = URL.createObjectURL(file);

    try {
      // Assuming path and fileName are defined elsewhere in your component
      const path =
        product.productType.toLowerCase() === "oil" ? "oil/" : "olive/";

      await uploadPhoto(imageUrl, product.productId, path);
      // uploadPhoto(image, result.right.productId, `restaurants/${variables.beer.restaurantId}/beers/`)
      console.log("Image uploaded successfully!");
      toast({
        title: "Image uploaded successfully.",
        description: "Your image has been uploaded.",
        status: "success",
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to upload the image.",
        status: "error",
      });
    }
    setImageLoading(false);
  };

  const hasCertifications =
    product.isNew ||
    (product.certifications && product.certifications.includes("DOP")) ||
    (product.certifications && product.certifications.includes("IGP")) ||
    (product.certifications && product.certifications.includes("BIO"));

  const path = product.productType.toLowerCase() === "oil" ? "oil" : "olive";
  const image = `${REACT_APP_CDN}/${path}/${product.productId}.png`;

  const standardProduct: Product = {
    __typename: "Product",
    attribute: product.attribute,
    certifications: product.certifications,
    description: product.description[lang],
    discount: product.discount,
    format: {
      __typename: "Format",
      boxType: product.format.boxType,
      boxTypeQuantity: product.format.boxTypeQuantity,
      boxWeight: product.format.boxWeight,
      description: product.format.description[lang],
      formatQuantity: product.format.formatQuantity,
      formatType: product.format.formatType,
      unit: product.format.unit,
      unitPrice: product.format.unitPrice,
      unitQuantity: product.format.unitQuantity,
    },
    isNew: product.isNew,
    isSoldOut: product.isSoldOut,
    isThisYear: product.isThisYear,
    line: product.line,
    name: product.name[lang],
    price: product.price,
    productId: product.productId,
    productType: product.productType,
    selectedQuantity: product.selectedQuantity,
    sizeType: product.sizeType,
    users: product.users,
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={2} spacing={10}>
        <Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mb={5}
            mr={5}
          >
            <Select
              value={lang}
              onChange={(e) => {
                setLang(e.target.value as "ita" | "en");
              }}
            >
              <option value="ita">ITA</option>
              <option value="en">EN</option>
            </Select>
          </Box>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Heading size="md">Product</Heading>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Stack spacing={4}>
                  <Box>ProductId: {product.productId}</Box>
                  <Box>ProductStatus: {product.productStatus}</Box>
                  <Select
                    value={product.productStatus}
                    onChange={(e) => {
                      setProduct({ ...product, productStatus: e.target.value });
                    }}
                  >
                    <option value="LIVE">Live</option>
                    <option value="DRAFT">DRAFT</option>
                  </Select>
                  <Box>RolType: {product.roleType}</Box>
                  <InputField
                    id="roleType"
                    label="Role Type"
                    extra={<></>}
                    placeholder="Role Type"
                    type="text"
                    mb="30px"
                    value={product.roleType}
                    onChange={(e) =>
                      setProduct({ ...product, roleType: e.target.value })
                    }
                  />
                  <Box>line: {product.line}</Box>
                  <Select
                    value={product.line}
                    onChange={(e) => {
                      setProduct({ ...product, line: e.target.value as LINE });
                    }}
                  >
                    <option value="NATALE">Natale</option>
                    <option value="CUSCUNÀ">Cuscunà</option>
                    <option value="DOP MONTE ETNA">DOP MONTE ETNA</option>
                  </Select>
                  <Box>name ita: {product.name.ita}</Box>
                  <InputField
                    id="name.ita"
                    label="Name ita"
                    extra={<></>}
                    placeholder="Name ita"
                    type="text"
                    mb="30px"
                    value={product.name.ita}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        name: {
                          ...product.name,
                          ita: e.target.value,
                        },
                      })
                    }
                  />
                  <Box>name en: {product.name.en}</Box>
                  <InputField
                    id="name.en"
                    label="Name en"
                    extra={<></>}
                    placeholder="Name en"
                    type="text"
                    mb="30px"
                    value={product.name.en}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        name: {
                          ...product.name,
                          en: e.target.value,
                        },
                      })
                    }
                  />
                  <Box>price: {formatPrice(product.price)} €</Box>
                  <InputField
                    id="totalPrice"
                    label="Total Price"
                    extra={<></>}
                    placeholder="Total Price"
                    type="number"
                    mb="30px"
                    value={product.price.toString()}
                    onChange={(e) =>
                      setProduct({ ...product, price: +e.target.value })
                    }
                  />
                  <Box>isThisYear: {product.isThisYear ? "true" : "false"}</Box>
                  <Select
                    value={product.isThisYear ? "true" : "false"}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        isThisYear: e.target.value === "true",
                      });
                    }}
                  >
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </Select>
                  <Box>
                    certifications:{" "}
                    {product.certifications &&
                      product.certifications
                        .map((certification) => certification)
                        .join(", ")}
                  </Box>
                  {/* Add checkbox for BIO, DOP and IGP */}
                  <CheckboxGroup
                    value={product.certifications ? product.certifications : []}
                  >
                    <Checkbox
                      value="BIO"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setProduct({
                            ...product,
                            certifications: [
                              ...(product.certifications || []),
                              "BIO",
                            ],
                          });
                        } else {
                          setProduct({
                            ...product,
                            certifications: product.certifications?.filter(
                              (certification) => certification !== "BIO"
                            ),
                          });
                        }
                      }}
                    >
                      BIO
                    </Checkbox>
                    <Checkbox
                      value="DOP"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setProduct({
                            ...product,
                            certifications: [
                              ...(product.certifications || []),
                              "DOP",
                            ],
                          });
                        } else {
                          setProduct({
                            ...product,
                            certifications: product.certifications?.filter(
                              (certification) => certification !== "DOP"
                            ),
                          });
                        }
                      }}
                    >
                      DOP
                    </Checkbox>
                    <Checkbox
                      value="IGP"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setProduct({
                            ...product,
                            certifications: [
                              ...(product.certifications || []),
                              "IGP",
                            ],
                          });
                        } else {
                          setProduct({
                            ...product,
                            certifications: product.certifications?.filter(
                              (certification) => certification !== "IGP"
                            ),
                          });
                        }
                      }}
                    >
                      IGP
                    </Checkbox>
                  </CheckboxGroup>
                  <Box>
                    attribute:
                    {product.attribute &&
                      product.attribute
                        .map((attribute) => attribute)
                        .join(", ")}
                  </Box>
                  {/* add inputfield that add string in a string array separateby comma */}
                  <InputField
                    id="attribute"
                    label="Attribute"
                    extra={<></>}
                    placeholder="Attribute"
                    type="text"
                    mb="30px"
                    value={
                      product.attribute ? product.attribute.join(", ") : ""
                    }
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        attribute: e.target.value
                          .split(",")
                          .map((item) => item.trim()),
                      })
                    }
                  />
                  <Box>description ita: {product.description.ita}</Box>
                  <InputField
                    id="description.ita"
                    label="Description ita"
                    extra={<></>}
                    placeholder="Description ita"
                    type="text"
                    mb="30px"
                    value={product.description.ita}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        description: {
                          ...product.description,
                          ita: e.target.value,
                        },
                      })
                    }
                  />
                  <Box>description en: {product.description.en}</Box>
                  <InputField
                    id="description.en"
                    label="Description en"
                    extra={<></>}
                    placeholder="Description en"
                    type="text"
                    mb="30px"
                    value={product.description.en}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        description: {
                          ...product.description,
                          en: e.target.value,
                        },
                      })
                    }
                  />
                  <Box>productType: {product.productType}</Box>
                  <Select
                    value={product.productType}
                    onChange={(e) => {
                      setProduct({ ...product, productType: e.target.value });
                    }}
                  >
                    <option value="OIL">Oil</option>
                    <option value="OLIVE">Olive</option>
                  </Select>
                  <Box>
                    sizeType: {product.sizeType ? product.sizeType : "null"}
                  </Box>
                  <Select
                    value={product.sizeType ? product.sizeType : "null"}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        sizeType:
                          e.target.value === "null" ? null : e.target.value,
                      });
                    }}
                  >
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="null">null</option>
                  </Select>
                  <Box>isNew: {product.isNew ? "true" : "false"}</Box>
                  <Select
                    value={product.isNew ? "true" : "false"}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        isNew: e.target.value === "true",
                      });
                    }}
                  >
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </Select>
                  <Box>
                    isSoldOut:
                    {product.isSoldOut ? "true" : "false"}
                  </Box>
                  <Select
                    value={product.isSoldOut ? "true" : "false"}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        isSoldOut: e.target.value === "true",
                      });
                    }}
                  >
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </Select>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Heading size="md">Discount</Heading>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Stack spacing={4}>
                  <Box>
                    discountPercentage:{" "}
                    {product.discount && product.discount.discountPercentage} %
                  </Box>
                  <InputField
                    id="discountPercentage"
                    label="Discount Percentage"
                    extra={<></>}
                    placeholder="Discount Percentage"
                    type="number"
                    mb="30px"
                    value={
                      product.discount
                        ? product.discount.discountPercentage.toString()
                        : ""
                    }
                    onChange={(e) => {
                      const discount = product.discount || {
                        discountPercentage: 0,
                        discountPrice: 0,
                        __typename: "Discount",
                      };
                      setProduct({
                        ...product,
                        discount: {
                          ...discount,
                          discountPercentage: Number(e.target.value),
                        },
                      });
                    }}
                  />
                  <Box>
                    discountPrice:{" "}
                    {product.discount &&
                      formatPrice(product.discount.discountPrice)}
                  </Box>
                  <InputField
                    id="discountPrice"
                    label="Discount Price"
                    extra={<></>}
                    placeholder="Discount Price"
                    type="number"
                    mb="30px"
                    value={
                      product.discount
                        ? product.discount.discountPrice.toString()
                        : ""
                    }
                    onChange={(e) => {
                      const discount = product.discount || {
                        discountPercentage: 0,
                        discountPrice: 0,
                        __typename: "Discount",
                      };
                      const priceWithoutDiscount = product.price;
                      const priceWithDiscount = Number(e.target.value);
                      const discountPercentage =
                        ((priceWithoutDiscount - priceWithDiscount) /
                          priceWithoutDiscount) *
                        100;
                      setProduct({
                        ...product,
                        discount: {
                          ...discount,
                          discountPrice: Number(e.target.value),
                          discountPercentage: +discountPercentage.toFixed(0),
                        },
                      });
                    }}
                  />
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Heading size="md">Format</Heading>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Stack spacing={4}>
                  <Box>boxType: {product.format.boxType}</Box>
                  <InputField
                    id="format.boxType"
                    label="Box Type"
                    extra={<></>}
                    placeholder="Box Type"
                    type="text"
                    mb="30px"
                    value={product.format.boxType}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: { ...product.format, boxType: e.target.value },
                      })
                    }
                  />
                  <Box>boxTypeQuantity: {product.format.boxTypeQuantity}</Box>
                  <InputField
                    id="format.boxTypeQuantity"
                    label="Box Type Quantity"
                    extra={<></>}
                    placeholder="Box Type Quantity"
                    type="number"
                    mb="30px"
                    value={product.format.boxTypeQuantity.toString()}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          boxTypeQuantity: +e.target.value,
                        },
                      })
                    }
                  />
                  <Box>boxWeight: {product.format.boxWeight}</Box>
                  <InputField
                    id="format.boxWeight"
                    label="Box Weight"
                    extra={<></>}
                    placeholder="Box Weight"
                    type="number"
                    mb="30px"
                    value={product.format.boxWeight.toString()}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          boxWeight: +e.target.value,
                        },
                      })
                    }
                  />
                  <Box>description ita: {product.format.description.ita}</Box>
                  <InputField
                    id="format.description.ita"
                    label="Description ita"
                    extra={<></>}
                    placeholder="Description ita"
                    type="text"
                    mb="30px"
                    value={product.format.description.ita}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          description: {
                            ...product.format.description,
                            ita: e.target.value,
                          },
                        },
                      })
                    }
                  />
                  <Box>description en: {product.format.description.en}</Box>
                  <InputField
                    id="format.description.en"
                    label="Description en"
                    extra={<></>}
                    placeholder="Description en"
                    type="text"
                    mb="30px"
                    value={product.format.description.en}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          description: {
                            ...product.format.description,
                            en: e.target.value,
                          },
                        },
                      })
                    }
                  />
                  <Box>formatQuantity: {product.format.formatQuantity}</Box>
                  <InputField
                    id="format.formatQuantity"
                    label="Format Quantity"
                    extra={<></>}
                    placeholder="Format Quantity"
                    type="number"
                    mb="30px"
                    value={product.format.formatQuantity.toString()}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          formatQuantity: +e.target.value,
                        },
                      })
                    }
                  />
                  <Box>formatType: {product.format.formatType}</Box>
                  <InputField
                    id="format.formatType"
                    label="Format Type"
                    extra={<></>}
                    placeholder="Format Type"
                    type="text"
                    mb="30px"
                    value={product.format.formatType}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          formatType: e.target.value,
                        },
                      })
                    }
                  />
                  <Box>unit: {product.format.unit}</Box>
                  <InputField
                    id="format.unit"
                    label="Unit"
                    extra={<></>}
                    placeholder="Unit"
                    type="text"
                    mb="30px"
                    value={product.format.unit}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: { ...product.format, unit: e.target.value },
                      })
                    }
                  />
                  <Box>unitPrice: {formatPrice(product.format.unitPrice)}</Box>
                  <InputField
                    id="format.unitPrice"
                    label="Unit Price"
                    extra={<></>}
                    placeholder="Unit Price"
                    type="number"
                    mb="30px"
                    value={product.format.unitPrice.toString()}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          unitPrice: +e.target.value,
                        },
                      })
                    }
                  />
                  <Box>unitQuantity: {product.format.unitQuantity}</Box>
                  <InputField
                    id="format.unitQuantity"
                    label="Unit Quantity"
                    extra={<></>}
                    placeholder="Unit Quantity"
                    type="number"
                    mb="30px"
                    value={product.format.unitQuantity.toString()}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        format: {
                          ...product.format,
                          unitQuantity: +e.target.value,
                        },
                      })
                    }
                  />
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Stack mt={10} justifyContent="space-around">
            <Button as="label" htmlFor="upload-button" cursor="pointer">
              Upload Image
              <input
                id="upload-button"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </Button>
            <Button onClick={() => mutateUpdateProduct()}>
              <Text>UPDATE PRODUCT</Text>
            </Button>
            <Button onClick={() => mutateCloneProduct()}>
              <Text>CLONE PRODUCT</Text>
            </Button>
            <Button onClick={() => mutateDeleteProduct()}>
              <Text>DELETE PRODUCT</Text>
            </Button>
          </Stack>
        </Box>
        <Box
          zIndex="99"
          position="fixed"
          right="35px"
          top={{ base: "130px", md: "80px", xl: "80px" }}
          display="flex"
          p="0px"
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bgColor={"green.300"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Box justifyContent="center" display="flex" bgColor={"green.100"}>
                <OrderModal
                  children={<OrderProductInfo product={standardProduct} />}
                  modalTitle={product.name[lang] ?? ""}
                  component={
                    <Box position="relative">
                      <AsyncImage
                        height={150}
                        src={`${image}?${uuidv4()}`}
                        alt={product.name[lang]}
                      />
                      {product.isSoldOut && (
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          bg="rgba(255, 255, 255, 0.7)"
                        >
                          <Text fontWeight="bold" fontSize="lg" color="orange">
                            Sold Out
                          </Text>
                        </Box>
                      )}
                    </Box>
                  }
                />
              </Box>

              <Stack
                flexDirection="row"
                display="flex"
                alignItems="baseline"
                flex={1}
                flexWrap="wrap"
                px={2}
                mb={hasCertifications ? 0 : "16px"}
              >
                {product.isThisYear && (
                  <Badge borderRadius="full" px="2" colorScheme="whiteAlpha">
                    2024
                  </Badge>
                )}
                {product.isNew && (
                  <Badge borderRadius="full" px="2" colorScheme="pink">
                    New
                  </Badge>
                )}
                {product.certifications &&
                  product.certifications.includes("DOP") && (
                    <Badge borderRadius="full" px="2" colorScheme="blue">
                      DOP
                    </Badge>
                  )}
                {product.certifications &&
                  product.certifications.includes("IGP") && (
                    <Badge borderRadius="full" px="2" colorScheme="yellow">
                      IGP
                    </Badge>
                  )}
                {product.certifications &&
                  product.certifications.includes("BIO") && (
                    <Badge borderRadius="full" px="2" colorScheme="green">
                      BIO
                    </Badge>
                  )}
              </Stack>

              <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                display={"flex"}
                flex={1}
                color={"white"}
                px={2}
              >
                {product.name[lang]}
              </Box>
              <Box pl={2} color={"white"} fontSize="sm">
                {`(1 pz - ${formatPrice(product.format.unitPrice)} €)`}
              </Box>
              <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                display={"flex"}
                flex={1}
                color={"white"}
                px={2}
              >
                {lang === "ita" ? "Linea" : "Line"}: {product.line}
              </Box>
              <Box
                color={"white"}
                px={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  fontWeight="bold"
                  pr={2}
                  style={{
                    textDecoration: product.discount ? "line-through" : "none",
                    color:
                      product.discount && product.discount.discountPrice > 0
                        ? "gray"
                        : "white",
                  }}
                >
                  {calculatePrice(standardProduct, false)} €
                </Box>
                {product.discount && product.discount.discountPrice > 0 && (
                  <Box color={"white"} display="flex" flexDirection="row">
                    <Box fontWeight="bold" pr={2}>
                      {calculatePrice(standardProduct, !!product.discount)} €
                    </Box>
                  </Box>
                )}
                <Box>
                  {product.discount && product.discount.discountPrice > 0 && (
                    <Badge
                      borderRadius="full"
                      px="2"
                      colorScheme="orange"
                      display="flex"
                      ml={"auto"}
                    >
                      {-product.discount.discountPercentage}%
                    </Badge>
                  )}
                </Box>
              </Box>

              <Box
                color={"white"}
                whiteSpace="pre-line"
                px={2}
                height="48px"
                noOfLines={2}
              >
                {product.format.description[lang]}
              </Box>
            </Stack>

            <Box
              fontSize="sm"
              display={"flex"}
              justifyContent={"flex-end"}
              color="white"
              px={2}
              pb={2}
            >
              <HStack maxW="320px" display="flex" flex={1}>
                <Button
                  colorScheme="green.600"
                  _hover={{
                    bg: "green.600",
                    color: "white",
                  }}
                  variant="outline"
                  isDisabled={
                    product.isSoldOut || product.selectedQuantity === 0
                  }
                >
                  -
                </Button>
                <Input
                  textAlign={"center"}
                  isDisabled={product.isSoldOut}
                  value={product.selectedQuantity}
                />
                <Button
                  colorScheme="teal"
                  _hover={{ bg: "green.600", color: "white" }}
                  isDisabled={
                    product.isSoldOut || product.selectedQuantity === 100
                  }
                >
                  +
                </Button>
              </HStack>
            </Box>
          </Stack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
