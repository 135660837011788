import { EmailInput, SendEmailMutationVariables } from "../../API";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import { sendEmail } from "graphql/mutations";

interface EmailApiInterface {
  sendEmail: (i: { email: EmailInput }) => Promise<string>;
}

const SendEmailSchema = z.object({
  data: z.object({
    sendEmail: z.string(),
  }),
});

export class EmailApi implements EmailApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  sendEmail = async (i: { email: EmailInput }) => {
    try {
      const queryResult = await this.api.query<SendEmailMutationVariables>({
        query: sendEmail,
        variables: i,
      });

      // Validate the mutation result using Zod
      const validatedData = SendEmailSchema.parse(queryResult);

      return validatedData.data.sendEmail;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const emailApi = new EmailApi(api);
