import { FinalOrderConnectionSchema, FinalOrderSchema } from "models/orders";
import {
  DeleteOrderMutationVariables,
  FinalOrder,
  FinalOrderConnection,
  FinalOrderInput,
  GetAllFinalOrdersBackQueryVariables,
  GetOrderByOrderIdQueryVariables,
  UpdateHistoryOrderMutationVariables,
  UpdateOrderMutationVariables,
} from "../../API";
import {
  getAllFinalOrdersBack,
  getOrderByOrderId,
} from "../../graphql/queries";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import {
  deleteOrder,
  updateHistoryOrder,
  updateOrder,
} from "graphql/mutations";

interface OrderApiInterface {
  getAllFinalOrdersBack: (i: {
    status: string;
    limit: number;
    nextToken?: string;
  }) => Promise<FinalOrderConnection>;
  getOrder: (i: { orderId: string }) => Promise<FinalOrder>;
  updateOrder: (i: { order: FinalOrderInput }) => Promise<FinalOrder>;
  deleteOrder: (i: { orderId: string; userId: string }) => Promise<FinalOrder>;
  updateHistoryOrder: (i: { order: FinalOrderInput }) => Promise<FinalOrder>;
}

const UpdateOrderSchema = z.object({
  data: z.object({
    updateOrder: FinalOrderSchema,
  }),
});

const UpdateHistoryOrderSchema = z.object({
  data: z.object({
    updateHistoryOrder: FinalOrderSchema,
  }),
});

const GetAllFinalOrdersBackQuerySchema = z.object({
  data: z.object({
    getAllFinalOrdersBack: FinalOrderConnectionSchema,
  }),
});

const GetOrderQuerySchema = z.object({
  data: z.object({
    getOrderByOrderId: FinalOrderSchema,
  }),
});

const DeleteOrderSchema = z.object({
  data: z.object({
    deleteOrder: FinalOrderSchema,
  }),
});

export class OrderApi implements OrderApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getAllFinalOrdersBack = async (i: {
    status: string;
    limit: number;
    nextToken?: string;
  }) => {
    try {
      // Fetch the data using your API
      const queryResult =
        await this.api.query<GetAllFinalOrdersBackQueryVariables>({
          query: getAllFinalOrdersBack,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData = GetAllFinalOrdersBackQuerySchema.parse(queryResult);

      return validatedData.data.getAllFinalOrdersBack;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  getOrder = async (i: { orderId: string }) => {
    try {
      const queryResult = await this.api.query<GetOrderByOrderIdQueryVariables>(
        {
          query: getOrderByOrderId,
          variables: i,
        }
      );

      // Validate the query result using Zod
      const validatedData = GetOrderQuerySchema.parse(queryResult);

      return validatedData.data.getOrderByOrderId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateOrder = async (i: { order: FinalOrderInput }) => {
    try {
      const queryResult = await this.api.query<UpdateOrderMutationVariables>({
        query: updateOrder,
        variables: i,
      });

      // Validate the mutation result using Zod
      const validatedData = UpdateOrderSchema.parse(queryResult);

      return validatedData.data.updateOrder;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateHistoryOrder = async (i: { order: FinalOrderInput }) => {
    try {
      const queryResult =
        await this.api.query<UpdateHistoryOrderMutationVariables>({
          query: updateHistoryOrder,
          variables: i,
        });

      // Validate the mutation result using Zod
      const validatedData = UpdateHistoryOrderSchema.parse(queryResult);

      return validatedData.data.updateHistoryOrder;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  deleteOrder = async (i: { orderId: string; userId: string }) => {
    try {
      // Fetch the data using your API
      const mutationResult = await this.api.query<DeleteOrderMutationVariables>(
        {
          query: deleteOrder,
          variables: i,
        }
      );

      // Validate the query result using Zod
      const validatedData = DeleteOrderSchema.parse(mutationResult);

      return validatedData.data.deleteOrder;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const orderApi = new OrderApi(api);
