import { FinalOrderInput } from "API";
import { formattedDate } from "utils/date";
import { formatPrice } from "utils/utils";

const { REACT_APP_CDN, REACT_APP_ENV } = process.env;

const orderStatus: Record<string, { it: string; en: string }> = {
  CREATED: {
    it: "Creato",
    en: "Created",
  },
  ARRIVED: {
    it: "Arrivato al nostro sistema",
    en: "Arrived at our system",
  },
  STARTED: {
    it: "È pronto per essere preparato",
    en: "It's ready to be prepared",
  },
  READY: {
    it: "Pronto",
    en: "Ready",
  },
  DELIVERING: {
    it: "È spedito ed è in consegna",
    en: "It's shipped and it's delivering",
  },
  DELIVERED: {
    it: "Consegnato",
    en: "Delivered",
  },
  CANCELED: {
    it: "Cancellato",
    en: "Canceled",
  },
  REJECTED: {
    it: "Rifiutato",
    en: "Rejected",
  },
};

export const htlmOrder = (order: FinalOrderInput, toPay?: boolean) => {
  const checkStatusEng = toPay
    ? "You can pay now"
    : "Check the status of your order";
  const checkStatusIt = toPay
    ? "Puoi pagare ora"
    : "Controlla lo stato del tuo ordine";
  const defaultImage = `${REACT_APP_CDN}/default.png`;
  const statusEn = orderStatus[order.status].en;
  const statusIt = orderStatus[order.status].it;
  const finalStatusEn = toPay ? checkStatusEng : statusEn;
  const finalStatusIt = toPay ? checkStatusIt : statusIt;
  const orderDate = formattedDate(order.tracking[1].update);
  const orderTotal = formatPrice(order.payment.totalPrice) + ` €`;
  const orderShipping = formatPrice(order.payment.totalShipping) + ` €`;
  const shippingRecipient = order.address.recipient;
  const shippingStreet = order.address.street;
  const shippingCity =
    order.address.country +
    ", " +
    order.address.city +
    " " +
    order.address.zipCode;

  const imageTableEn = order.products
    .map((product) => {
      const path =
        product.productType.toLowerCase() === "oil" ? "oil" : "olive";
      const image = `${REACT_APP_CDN}/${path}/${product.productId}.png`;
      const price =
        product.discount && product.discount.discountPrice
          ? product.discount.discountPrice
          : product.price;
      return `
      <tr style="border-collapse: collapse">
          <td align="left" style="padding: 0; margin: 0;">
              <table cellpadding="0" cellspacing="0" style="border-collapse: collapse;">
                  <tr>
                      <td align="center" style="padding: 0; margin: 0;">
                          <img src="${image}" alt="Product Image" style="display: block; width: 100px; height: auto; border: 0; outline: none;"/>
                      </td>
                      <td style="padding: 0 20px; margin: 0;">
                      <h3
              style="
                margin: 0;
                font-family: arial, 'helvetica neue',
                  helvetica, sans-serif;
                mso-line-height-rule: exactly;
                letter-spacing: 0;
                font-size: 19px;
                font-style: normal;
                font-weight: normal;
                line-height: 23px;
                color: #48bb78;
              "
            >
                          <p><strong>${product.name.en}</strong></p>
                          </h3>
                          <p>${product.format.description.en}</p>
                          <p><strong>Quantity</strong>: ${
                            product.selectedQuantity
                          }</p>
                          <p><strong>Price</strong>: ${formatPrice(price)} €</p>
                      </td>
                  </tr>
              </table>
          </td>
      </tr>`;
    })
    .join("");

  const imageTableIt = order.products
    .map((product) => {
      const path =
        product.productType.toLowerCase() === "oil" ? "oil" : "olive";
      const image = `${REACT_APP_CDN}/${path}/${product.productId}.png`;
      const price =
        product.discount && product.discount.discountPrice
          ? product.discount.discountPrice
          : product.price;
      return `
      <tr style="border-collapse: collapse">
          <td align="left" style="padding: 0; margin: 0;">
              <table cellpadding="0" cellspacing="0" style="border-collapse: collapse;">
                  <tr>
                      <td align="center" style="padding: 0; margin: 0;">
                          <img src="${image}" alt="Product Image" style="display: block; width: 100px; height: auto; border: 0; outline: none;"/>
                      </td>
                      <td style="padding: 0 20px; margin: 0;">
                      <h3
              style="
                margin: 0;
                font-family: arial, 'helvetica neue',
                  helvetica, sans-serif;
                mso-line-height-rule: exactly;
                letter-spacing: 0;
                font-size: 19px;
                font-style: normal;
                font-weight: normal;
                line-height: 23px;
                color: #48bb78;
              "
            >
                          <p><strong>${product.name.ita}</strong></p>
                          </h3>
                          <p>${product.format.description.ita}</p>
                          <p><strong>Quantity</strong>: ${
                            product.selectedQuantity
                          }</p>
                          <p><strong>Price</strong>: ${formatPrice(price)} €</p>
                      </td>
                  </tr>
              </table>
          </td>
      </tr>`;
    })
    .join("");

  const base_url =
    REACT_APP_ENV === "development"
      ? "https://www.app.dev.oleificiocuscuna.com"
      : "https://www.oleificiocuscuna.com";

  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
  dir="ltr"
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  lang="en"
>
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title>Message</title>
    <!--[if (mso 16)]>
      <style type="text/css">
        a {
          text-decoration: none;
        }
      </style>
    <![endif]-->
    <!--[if gte mso 9
      ]><style>
        sup {
          font-size: 100% !important;
        }
      </style><!
    [endif]-->
    <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG></o:AllowPNG>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
    <style type="text/css">
      .rollover:hover .rollover-first {
        max-height: 0px !important;
        display: none !important;
      }
      .rollover:hover .rollover-second {
        max-height: none !important;
        display: block !important;
      }
      .rollover span {
        font-size: 0px;
      }
      u + .body img ~ div div {
        display: none;
      }
      #outlook a {
        padding: 0;
      }
      span.MsoHyperlink,
      span.MsoHyperlinkFollowed {
        color: inherit;
        mso-style-priority: 99;
      }
      a.es-button {
        mso-style-priority: 100 !important;
        text-decoration: none !important;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      .es-desk-hidden {
        display: none;
        float: left;
        overflow: hidden;
        width: 0;
        max-height: 0;
        line-height: 0;
        mso-hide: all;
      }
      .es-button-border:hover {
        border-color: #7dbf44 #7dbf44 #7dbf44 #7dbf44 !important;
        background: #7dbf44 !important;
      }
      .es-button-border:hover a.es-button,
      .es-button-border:hover button.es-button {
        background: #7dbf44 !important;
        color: #ffffff !important;
      }
      td .es-button-border:hover a.es-button-1709289103104 {
        background: #389860 !important;
      }
      td .es-button-border-1709289103112:hover {
        background: #389860 !important;
      }
      @media only screen and (max-width: 600px) {
        .es-m-p20b {
          padding-bottom: 20px !important;
        }
        *[class="gmail-fix"] {
          display: none !important;
        }
        p,
        a {
          line-height: 150% !important;
        }
        h1,
        h1 a {
          line-height: 120% !important;
        }
        h2,
        h2 a {
          line-height: 120% !important;
        }
        h3,
        h3 a {
          line-height: 120% !important;
        }
        h4,
        h4 a {
          line-height: 120% !important;
        }
        h5,
        h5 a {
          line-height: 120% !important;
        }
        h6,
        h6 a {
          line-height: 120% !important;
        }
        h1 {
          font-size: 30px !important;
          text-align: center;
        }
        h2 {
          font-size: 22px !important;
          text-align: center;
        }
        h3 {
          font-size: 20px !important;
          text-align: center;
        }
        h4 {
          font-size: 24px !important;
          text-align: left;
        }
        h5 {
          font-size: 20px !important;
          text-align: left;
        }
        h6 {
          font-size: 16px !important;
          text-align: left;
        }
        .es-header-body h1 a,
        .es-content-body h1 a,
        .es-footer-body h1 a {
          font-size: 30px !important;
        }
        .es-header-body h2 a,
        .es-content-body h2 a,
        .es-footer-body h2 a {
          font-size: 22px !important;
        }
        .es-header-body h3 a,
        .es-content-body h3 a,
        .es-footer-body h3 a {
          font-size: 20px !important;
        }
        .es-header-body h4 a,
        .es-content-body h4 a,
        .es-footer-body h4 a {
          font-size: 24px !important;
        }
        .es-header-body h5 a,
        .es-content-body h5 a,
        .es-footer-body h5 a {
          font-size: 20px !important;
        }
        .es-header-body h6 a,
        .es-content-body h6 a,
        .es-footer-body h6 a {
          font-size: 16px !important;
        }
        .es-menu td a {
          font-size: 16px !important;
        }
        .es-header-body p,
        .es-header-body a {
          font-size: 16px !important;
        }
        .es-content-body p,
        .es-content-body a {
          font-size: 14px !important;
        }
        .es-footer-body p,
        .es-footer-body a {
          font-size: 14px !important;
        }
        .es-infoblock p,
        .es-infoblock a {
          font-size: 12px !important;
        }
        .es-m-txt-c,
        .es-m-txt-c h1,
        .es-m-txt-c h2,
        .es-m-txt-c h3,
        .es-m-txt-c h4,
        .es-m-txt-c h5,
        .es-m-txt-c h6 {
          text-align: center !important;
        }
        .es-m-txt-r,
        .es-m-txt-r h1,
        .es-m-txt-r h2,
        .es-m-txt-r h3,
        .es-m-txt-r h4,
        .es-m-txt-r h5,
        .es-m-txt-r h6 {
          text-align: right !important;
        }
        .es-m-txt-j,
        .es-m-txt-j h1,
        .es-m-txt-j h2,
        .es-m-txt-j h3,
        .es-m-txt-j h4,
        .es-m-txt-j h5,
        .es-m-txt-j h6 {
          text-align: justify !important;
        }
        .es-m-txt-l,
        .es-m-txt-l h1,
        .es-m-txt-l h2,
        .es-m-txt-l h3,
        .es-m-txt-l h4,
        .es-m-txt-l h5,
        .es-m-txt-l h6 {
          text-align: left !important;
        }
        .es-m-txt-r img,
        .es-m-txt-c img,
        .es-m-txt-l img {
          display: inline !important;
        }
        .es-m-txt-r .rollover:hover .rollover-second,
        .es-m-txt-c .rollover:hover .rollover-second,
        .es-m-txt-l .rollover:hover .rollover-second {
          display: inline !important;
        }
        .es-m-txt-r .rollover span,
        .es-m-txt-c .rollover span,
        .es-m-txt-l .rollover span {
          line-height: 0 !important;
          font-size: 0 !important;
        }
        .es-spacer {
          display: inline-table;
        }
        a.es-button,
        button.es-button {
          font-size: 20px !important;
          line-height: 120% !important;
        }
        a.es-button,
        button.es-button,
        .es-button-border {
          display: block !important;
        }
        .es-m-fw,
        .es-m-fw.es-fw,
        .es-m-fw .es-button {
          display: block !important;
        }
        .es-m-il,
        .es-m-il .es-button,
        .es-social,
        .es-social td,
        .es-menu {
          display: inline-block !important;
        }
        .es-adaptive table,
        .es-left,
        .es-right {
          width: 100% !important;
        }
        .es-content table,
        .es-header table,
        .es-footer table,
        .es-content,
        .es-footer,
        .es-header {
          width: 100% !important;
          max-width: 600px !important;
        }
        .adapt-img {
          width: 100% !important;
          height: auto !important;
        }
        .es-mobile-hidden,
        .es-hidden {
          display: none !important;
        }
        .es-desk-hidden {
          width: auto !important;
          overflow: visible !important;
          float: none !important;
          max-height: inherit !important;
          line-height: inherit !important;
        }
        tr.es-desk-hidden {
          display: table-row !important;
        }
        table.es-desk-hidden {
          display: table !important;
        }
        td.es-desk-menu-hidden {
          display: table-cell !important;
        }
        .es-menu td {
          width: 1% !important;
        }
        table.es-table-not-adapt,
        .esd-block-html table {
          width: auto !important;
        }
        .es-social td {
          padding-bottom: 10px;
        }
        .h-auto {
          height: auto !important;
        }
      }
      @media screen and (max-width: 384px) {
        .mail-message-content {
          width: 414px !important;
        }
      }
    </style>
  </head>
  <body class="body" style="width: 100%; height: 100%; padding: 0; margin: 0">
    <div
      dir="ltr"
      class="es-wrapper-color"
      lang="en"
      style="background-color: #f6f6f6"
    >
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#f6f6f6"></v:fill>
        </v:background>
      <![endif]-->
      <table
        class="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        role="none"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          border-collapse: collapse;
          border-spacing: 0px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-repeat: repeat;
          background-position: center top;
          background-color: #f6f6f6;
        "
      >
        <tr style="border-collapse: collapse">
          <td valign="top" style="padding: 0; margin: 0">
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-header"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
                background-color: transparent;
                background-repeat: repeat;
                background-position: center top;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    class="es-header-body"
                    cellspacing="0"
                    cellpadding="0"
                    bgcolor="#ffffff"
                    align="center"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-bottom: 10px;
                          padding-left: 20px;
                        "
                      >
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          width="100%"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="left"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-bottom: 5px;
                                      font-size: 0px;
                                    "
                                  >
                                    <a
                                      target="_blank"
                                      style="
                                        mso-line-height-rule: exactly;
                                        text-decoration: none;
                                        color: #659c35;
                                        font-size: 16px;
                                      "
                                      ><img
                                        src=${defaultImage}
                                        alt=""
                                        style="
                                          display: block;
                                          font-size: 14px;
                                          border: 0;
                                          outline: none;
                                          text-decoration: none;
                                        "
                                        class="adapt-img"
                                        height="80"
                                    /></a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-content"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    bgcolor="#ffffff"
                    class="es-content-body"
                    align="center"
                    cellpadding="0"
                    cellspacing="0"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          padding: 0;
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-left: 20px;
                          background-position: center top;
                        "
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="center"
                              valign="top"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="padding: 0; margin: 0"
                                  >
                                    <h2
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 26px;
                                        font-style: normal;
                                        font-weight: bold;
                                        line-height: 31px;
                                        color: #48bb78;
                                      "
                                    >
                                      Order state: ${finalStatusEn}
                                    </h2>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 10px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${checkStatusEng}
                                    </p>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      margin: 0;
                                      padding-top: 20px;
                                      padding-right: 10px;
                                      padding-bottom: 20px;
                                      padding-left: 10px;
                                    "
                                  >
                                    <span
                                      class="es-button-border-1709289103112 es-button-border"
                                      style="
                                        border-style: solid;
                                        border-color: #659c35;
                                        background: #48bb78;
                                        border-width: 0px;
                                        display: inline-block;
                                        border-radius: 0px;
                                        width: auto;
                                      "
                                      ><a
                                      href="${base_url}/orders"
                                        class="es-button es-button-1709289103104"
                                        target="_blank"
                                        style="
                                          mso-style-priority: 100 !important;
                                          text-decoration: none !important;
                                          mso-line-height-rule: exactly;
                                          color: #ffffff;
                                          font-size: 18px;
                                          padding: 10px 20px;
                                          display: inline-block;
                                          background: #48bb78;
                                          border-radius: 0px;
                                          font-family: arial, 'helvetica neue',
                                            helvetica, sans-serif;
                                          font-weight: normal;
                                          font-style: normal;
                                          line-height: 22px;
                                          width: auto;
                                          text-align: center;
                                          letter-spacing: 0;
                                          mso-padding-alt: 0;
                                          mso-border-alt: 10px solid #48bb78;
                                        "
                                        >View order status</a
                                      ></span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-bottom: 10px;
                          padding-left: 20px;
                          background-position: center top;
                        "
                      >
                        <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:280px" valign="top"><![endif]-->
                        <table
                          class="es-left"
                          cellspacing="0"
                          cellpadding="0"
                          align="left"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: left;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              class="es-m-p20b"
                              align="left"
                              style="padding: 0; margin: 0; width: 280px"
                            >
                              <table
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: separate;
                                  border-spacing: 0px;
                                  border-left: 1px solid transparent;
                                  border-top: 1px solid transparent;
                                  border-bottom: 1px solid transparent;
                                  background-color: #efefef;
                                  background-position: center top;
                                "
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                bgcolor="#efefef"
                                role="presentation"
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      margin: 0;
                                      padding-top: 20px;
                                      padding-right: 20px;
                                      padding-bottom: 10px;
                                      padding-left: 20px;
                                    "
                                  >
                                    <h4
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 17px;
                                        color: #48bb78;
                                      "
                                    >
                                      SUMMARY:
                                    </h4>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-right: 20px;
                                      padding-left: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    <table
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        border-spacing: 0px;
                                        width: 100%;
                                      "
                                      class="cke_show_border"
                                      cellspacing="1"
                                      cellpadding="1"
                                      border="0"
                                      align="left"
                                      role="presentation"
                                    >
                                      <tr style="border-collapse: collapse">
                                        <td
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            font-size: 14px;
                                            line-height: 21px;
                                          "
                                        >
                                          Order Date:
                                        </td>
                                        <td style="padding: 0; margin: 0">
                                          <strong
                                            ><span
                                              style="
                                                font-size: 14px;
                                                line-height: 21px;
                                              "
                                              >May&nbsp;21, 2019</span
                                            ></strong
                                          >
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            font-size: 14px;
                                            line-height: 21px;
                                          "
                                        >
                                          Order Shipping price:
                                        </td>
                                        <td style="padding: 0; margin: 0">
                                          <strong
                                            ><span
                                              style="
                                                font-size: 14px;
                                                line-height: 21px;
                                              "
                                              >${orderShipping}</span
                                            ></strong
                                          >
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            font-size: 14px;
                                            line-height: 21px;
                                          "
                                        >
                                          Order Total:
                                        </td>
                                        <td style="padding: 0; margin: 0">
                                          <strong
                                            ><span
                                              style="
                                                font-size: 14px;
                                                line-height: 21px;
                                              "
                                              >${orderTotal}</span
                                            ></strong
                                          >
                                        </td>
                                      </tr>
                                    </table>
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      <br />
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td><td style="width:0px"></td><td style="width:280px" valign="top"><![endif]-->
                        <table
                          class="es-right"
                          cellspacing="0"
                          cellpadding="0"
                          align="right"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: right;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="left"
                              style="padding: 0; margin: 0; width: 280px"
                            >
                              <table
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: separate;
                                  border-spacing: 0px;
                                  border-left: 1px solid transparent;
                                  border-right: 1px solid transparent;
                                  border-top: 1px solid transparent;
                                  border-bottom: 1px solid transparent;
                                  background-color: #efefef;
                                  background-position: center top;
                                "
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                bgcolor="#efefef"
                                role="presentation"
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      margin: 0;
                                      padding-top: 20px;
                                      padding-right: 20px;
                                      padding-bottom: 10px;
                                      padding-left: 20px;
                                    "
                                  >
                                    <h4
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 17px;
                                        color: #48bb78;
                                      "
                                    >
                                      SHIPPING ADDRESS:
                                    </h4>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-right: 20px;
                                      padding-left: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${shippingRecipient}
                                    </p>
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${shippingStreet}
                                    </p>
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${shippingCity}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-content"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    bgcolor="#ffffff"
                    class="es-content-body"
                    align="center"
                    cellpadding="0"
                    cellspacing="0"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-right: 20px;
                          padding-bottom: 10px;
                          padding-left: 20px;
                          padding-top: 10px;
                          background-position: center top;
                        "
                      >
                        <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:154px" valign="top"><![endif]-->
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="es-left"
                          align="left"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: left;
                          "
                        >
                          ${imageTableEn}
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-content"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    bgcolor="#ffffff"
                    class="es-content-body"
                    align="center"
                    cellpadding="0"
                    cellspacing="0"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                  <hr width="30%">
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-right: 20px;
                          padding-left: 20px;
                          padding-top: 30px;
                          padding-bottom: 30px;
                        "
                      >
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          width="100%"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="left"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  background-position: center center;
                                "
                                role="presentation"
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="padding: 0; margin: 0"
                                  >
                                    <h4
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 17px;
                                        color: #48bb78;
                                      "
                                    >
                                      Contact Us:
                                    </h4>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 10px;
                                      padding-bottom: 15px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    ></p>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td style="padding: 0; margin: 0">
                                    <table
                                      class="es-table-not-adapt"
                                      cellspacing="0"
                                      cellpadding="0"
                                      role="presentation"
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        border-spacing: 0px;
                                      "
                                    >
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-bottom: 5px;
                                            padding-right: 10px;
                                            padding-top: 5px;
                                            font-size: 0;
                                          "
                                        >
                                          <img
                                            src="https://efuobfw.stripocdn.email/content/guids/CABINET_45fbd8c6c971a605c8e5debe242aebf1/images/30981556869899567.png"
                                            alt=""
                                            width="16"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                        <td
                                          align="left"
                                          style="padding: 0; margin: 0"
                                        >
                                          <table
                                            width="100%"
                                            cellspacing="0"
                                            cellpadding="0"
                                            role="presentation"
                                            style="
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                              border-collapse: collapse;
                                              border-spacing: 0px;
                                            "
                                          >
                                            <tr
                                              style="border-collapse: collapse"
                                            >
                                              <td
                                                align="left"
                                                style="padding: 0; margin: 0"
                                              >
                                                <p
                                                  style="
                                                    margin: 0;
                                                    mso-line-height-rule: exactly;
                                                    font-family: arial,
                                                      'helvetica neue',
                                                      helvetica, sans-serif;
                                                    line-height: 21px;
                                                    letter-spacing: 0;
                                                    color: #333333;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="mailto:sales@oleificiocuscuna.com"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                    >sales@oleificiocuscuna.com</a
                                                  >
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-bottom: 5px;
                                            padding-right: 10px;
                                            padding-top: 5px;
                                            font-size: 0;
                                          "
                                        >
                                          <img
                                            src="https://efuobfw.stripocdn.email/content/guids/CABINET_45fbd8c6c971a605c8e5debe242aebf1/images/58031556869792224.png"
                                            alt=""
                                            width="16"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                        <td
                                          align="left"
                                          style="padding: 0; margin: 0"
                                        >
                                          <table
                                            width="100%"
                                            cellspacing="0"
                                            cellpadding="0"
                                            role="presentation"
                                            style="
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                              border-collapse: collapse;
                                              border-spacing: 0px;
                                            "
                                          >
                                            <tr
                                              style="border-collapse: collapse"
                                            >
                                              <td
                                                align="left"
                                                style="padding: 0; margin: 0"
                                              >
                                                <p
                                                  style="
                                                    margin: 0;
                                                    mso-line-height-rule: exactly;
                                                    font-family: arial,
                                                      'helvetica neue',
                                                      helvetica, sans-serif;
                                                    line-height: 21px;
                                                    letter-spacing: 0;
                                                    color: #333333;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="tel:+39095687302"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                    >+095687302</a
                                                  >
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-bottom: 5px;
                                            padding-right: 10px;
                                            padding-top: 5px;
                                            font-size: 0;
                                          "
                                        >
                                          <img
                                            src="https://efuobfw.stripocdn.email/content/guids/CABINET_45fbd8c6c971a605c8e5debe242aebf1/images/78111556870146007.png"
                                            alt=""
                                            width="16"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                        <td
                                          align="left"
                                          style="padding: 0; margin: 0"
                                        >
                                          <table
                                            width="100%"
                                            cellspacing="0"
                                            cellpadding="0"
                                            role="presentation"
                                            style="
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                              border-collapse: collapse;
                                              border-spacing: 0px;
                                            "
                                          >
                                            <tr
                                              style="border-collapse: collapse"
                                            >
                                              <td
                                                align="left"
                                                style="padding: 0; margin: 0"
                                              >
                                                <p
                                                  style="
                                                    margin: 0;
                                                    mso-line-height-rule: exactly;
                                                    font-family: arial,
                                                      'helvetica neue',
                                                      helvetica, sans-serif;
                                                    line-height: 21px;
                                                    letter-spacing: 0;
                                                    color: #333333;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  Biancavilla, CT 95033, Italy
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-footer"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
                background-color: transparent;
                background-repeat: repeat;
                background-position: center top;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    class="es-footer-body"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #333333;
                      width: 600px;
                    "
                    cellspacing="0"
                    cellpadding="0"
                    bgcolor="#333333"
                    align="center"
                    role="none"
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        style="
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-left: 20px;
                          padding-bottom: 15px;
                          background-color: #48bb78;
                        "
                        bgcolor="#48bb78"
                        align="left"
                      >
                        <table
                          width="100%"
                          cellspacing="0"
                          cellpadding="0"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              valign="top"
                              align="center"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-bottom: 15px;
                                      font-size: 0;
                                    "
                                  >
                                    <table
                                      class="es-table-not-adapt es-social"
                                      cellspacing="0"
                                      cellpadding="0"
                                      role="presentation"
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        border-spacing: 0px;
                                      "
                                    >
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="center"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-right: 15px;
                                          "
                                        >
                                          <img
                                            title="Facebook"
                                            src="https://efuobfw.stripocdn.email/content/assets/img/social-icons/circle-white/facebook-circle-white.png"
                                            alt="Fb"
                                            width="32"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                        
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="padding: 0; margin: 0"
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 20px;
                                        letter-spacing: 0;
                                        color: #ffffff;
                                        font-size: 13px;
                                      "
                                    ></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div
      dir="ltr"
      class="es-wrapper-color"
      lang="en"
      style="background-color: #f6f6f6"
    >
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#f6f6f6"></v:fill>
        </v:background>
      <![endif]-->
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#f6f6f6"></v:fill>
        </v:background>
      <![endif]-->
      <table
        class="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        role="none"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          border-collapse: collapse;
          border-spacing: 0px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-repeat: repeat;
          background-position: center top;
          background-color: #f6f6f6;
        "
      >
        <tr style="border-collapse: collapse">
          <td valign="top" style="padding: 0; margin: 0">
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-header"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
                background-color: transparent;
                background-repeat: repeat;
                background-position: center top;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    class="es-header-body"
                    cellspacing="0"
                    cellpadding="0"
                    bgcolor="#ffffff"
                    align="center"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-bottom: 10px;
                          padding-left: 20px;
                        "
                      >
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          width="100%"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="left"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-bottom: 5px;
                                      font-size: 0px;
                                    "
                                  >
                                    <a
                                      target="_blank"
                                      style="
                                        mso-line-height-rule: exactly;
                                        text-decoration: none;
                                        color: #659c35;
                                        font-size: 16px;
                                      "
                                      ><img
                                        src=${defaultImage}
                                        alt=""
                                        style="
                                          display: block;
                                          font-size: 14px;
                                          border: 0;
                                          outline: none;
                                          text-decoration: none;
                                        "
                                        class="adapt-img"
                                        height="80"
                                    /></a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-content"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    bgcolor="#ffffff"
                    class="es-content-body"
                    align="center"
                    cellpadding="0"
                    cellspacing="0"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          padding: 0;
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-left: 20px;
                          background-position: center top;
                        "
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="center"
                              valign="top"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="padding: 0; margin: 0"
                                  >
                                    <h2
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 26px;
                                        font-style: normal;
                                        font-weight: bold;
                                        line-height: 31px;
                                        color: #48bb78;
                                      "
                                    >
                                      Stato dell'ordine: ${finalStatusIt}
                                    </h2>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 10px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${checkStatusIt}
                                    </p>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      margin: 0;
                                      padding-top: 20px;
                                      padding-right: 10px;
                                      padding-bottom: 20px;
                                      padding-left: 10px;
                                    "
                                  >
                                    <span
                                      class="es-button-border-1709289103112 es-button-border"
                                      style="
                                        border-style: solid;
                                        border-color: #659c35;
                                        background: #48bb78;
                                        border-width: 0px;
                                        display: inline-block;
                                        border-radius: 0px;
                                        width: auto;
                                      "
                                      ><a
                                        href="${base_url}/orders"
                                        class="es-button es-button-1709289103104"
                                        target="_blank"
                                        style="
                                          mso-style-priority: 100 !important;
                                          text-decoration: none !important;
                                          mso-line-height-rule: exactly;
                                          color: #ffffff;
                                          font-size: 18px;
                                          padding: 10px 20px;
                                          display: inline-block;
                                          background: #48bb78;
                                          border-radius: 0px;
                                          font-family: arial, 'helvetica neue',
                                            helvetica, sans-serif;
                                          font-weight: normal;
                                          font-style: normal;
                                          line-height: 22px;
                                          width: auto;
                                          text-align: center;
                                          letter-spacing: 0;
                                          mso-padding-alt: 0;
                                          mso-border-alt: 10px solid #48bb78;
                                        "
                                        >Guarda lo stato dell'ordine</a
                                      ></span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-bottom: 10px;
                          padding-left: 20px;
                          background-position: center top;
                        "
                      >
                        <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:280px" valign="top"><![endif]-->
                        <table
                          class="es-left"
                          cellspacing="0"
                          cellpadding="0"
                          align="left"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: left;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              class="es-m-p20b"
                              align="left"
                              style="padding: 0; margin: 0; width: 280px"
                            >
                              <table
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: separate;
                                  border-spacing: 0px;
                                  border-left: 1px solid transparent;
                                  border-top: 1px solid transparent;
                                  border-bottom: 1px solid transparent;
                                  background-color: #efefef;
                                  background-position: center top;
                                "
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                bgcolor="#efefef"
                                role="presentation"
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      margin: 0;
                                      padding-top: 20px;
                                      padding-right: 20px;
                                      padding-bottom: 10px;
                                      padding-left: 20px;
                                    "
                                  >
                                    <h4
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 17px;
                                        color: #48bb78;
                                      "
                                    >
                                      RIEPILOGO:
                                    </h4>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-right: 20px;
                                      padding-left: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    <table
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        border-spacing: 0px;
                                        width: 100%;
                                      "
                                      class="cke_show_border"
                                      cellspacing="1"
                                      cellpadding="1"
                                      border="0"
                                      align="left"
                                      role="presentation"
                                    >
                                      <tr style="border-collapse: collapse">
                                        <td
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            font-size: 14px;
                                            line-height: 21px;
                                          "
                                        >
                                          Data ordine:
                                        </td>
                                        <td style="padding: 0; margin: 0">
                                          <strong
                                            ><span
                                              style="
                                                font-size: 14px;
                                                line-height: 21px;
                                              "
                                              >${orderDate}</span
                                            ></strong
                                          >
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            font-size: 14px;
                                            line-height: 21px;
                                          "
                                        >
                                          Spedizione:
                                        </td>
                                        <td style="padding: 0; margin: 0">
                                          <strong
                                            ><span
                                              style="
                                                font-size: 14px;
                                                line-height: 21px;
                                              "
                                              >${orderShipping}</span
                                            ></strong
                                          >
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            font-size: 14px;
                                            line-height: 21px;
                                          "
                                        >
                                          Totale ordine:
                                        </td>
                                        <td style="padding: 0; margin: 0">
                                          <strong
                                            ><span
                                              style="
                                                font-size: 14px;
                                                line-height: 21px;
                                              "
                                              >${orderTotal}</span
                                            ></strong
                                          >
                                        </td>
                                      </tr>
                                    </table>
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      <br />
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td><td style="width:0px"></td><td style="width:280px" valign="top"><![endif]-->
                        <table
                          class="es-right"
                          cellspacing="0"
                          cellpadding="0"
                          align="right"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: right;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="left"
                              style="padding: 0; margin: 0; width: 280px"
                            >
                              <table
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: separate;
                                  border-spacing: 0px;
                                  border-left: 1px solid transparent;
                                  border-right: 1px solid transparent;
                                  border-top: 1px solid transparent;
                                  border-bottom: 1px solid transparent;
                                  background-color: #efefef;
                                  background-position: center top;
                                "
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                bgcolor="#efefef"
                                role="presentation"
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      margin: 0;
                                      padding-top: 20px;
                                      padding-right: 20px;
                                      padding-bottom: 10px;
                                      padding-left: 20px;
                                    "
                                  >
                                    <h4
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 17px;
                                        color: #48bb78;
                                      "
                                    >
                                      INDIRIZZO DI SPEDIZIONE:
                                    </h4>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-right: 20px;
                                      padding-left: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${shippingRecipient}
                                    </p>
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${shippingStreet}
                                    </p>
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    >
                                      ${shippingCity}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-content"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    bgcolor="#ffffff"
                    class="es-content-body"
                    align="center"
                    cellpadding="0"
                    cellspacing="0"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-right: 20px;
                          padding-bottom: 10px;
                          padding-left: 20px;
                          padding-top: 10px;
                          background-position: center top;
                        "
                      >
                        <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:154px" valign="top"><![endif]-->
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="es-left"
                          align="left"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: left;
                          "
                        >
                          ${imageTableIt}
                        </table>
                        
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-content"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    bgcolor="#ffffff"
                    class="es-content-body"
                    align="center"
                    cellpadding="0"
                    cellspacing="0"
                    role="none"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                  <hr width="30%">
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-right: 20px;
                          padding-left: 20px;
                          padding-top: 30px;
                          padding-bottom: 30px;
                        "
                      >
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          width="100%"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="left"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  background-position: center center;
                                "
                                role="presentation"
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="padding: 0; margin: 0"
                                  >
                                    <h4
                                      style="
                                        margin: 0;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        mso-line-height-rule: exactly;
                                        letter-spacing: 0;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: normal;
                                        line-height: 17px;
                                        color: #48bb78;
                                      "
                                    >
                                      Contattaci:
                                    </h4>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 10px;
                                      padding-bottom: 15px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        letter-spacing: 0;
                                        color: #333333;
                                        font-size: 14px;
                                      "
                                    ></p>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td style="padding: 0; margin: 0">
                                    <table
                                      class="es-table-not-adapt"
                                      cellspacing="0"
                                      cellpadding="0"
                                      role="presentation"
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        border-spacing: 0px;
                                      "
                                    >
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-bottom: 5px;
                                            padding-right: 10px;
                                            padding-top: 5px;
                                            font-size: 0;
                                          "
                                        >
                                          <img
                                            src="https://efuobfw.stripocdn.email/content/guids/CABINET_45fbd8c6c971a605c8e5debe242aebf1/images/30981556869899567.png"
                                            alt=""
                                            width="16"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                        <td
                                          align="left"
                                          style="padding: 0; margin: 0"
                                        >
                                          <table
                                            width="100%"
                                            cellspacing="0"
                                            cellpadding="0"
                                            role="presentation"
                                            style="
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                              border-collapse: collapse;
                                              border-spacing: 0px;
                                            "
                                          >
                                            <tr
                                              style="border-collapse: collapse"
                                            >
                                              <td
                                                align="left"
                                                style="padding: 0; margin: 0"
                                              >
                                                <p
                                                  style="
                                                    margin: 0;
                                                    mso-line-height-rule: exactly;
                                                    font-family: arial,
                                                      'helvetica neue',
                                                      helvetica, sans-serif;
                                                    line-height: 21px;
                                                    letter-spacing: 0;
                                                    color: #333333;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="mailto:sales@oleificiocuscuna.com"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                    >sales@oleificiocuscuna.com</a
                                                  >
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-bottom: 5px;
                                            padding-right: 10px;
                                            padding-top: 5px;
                                            font-size: 0;
                                          "
                                        >
                                          <img
                                            src="https://efuobfw.stripocdn.email/content/guids/CABINET_45fbd8c6c971a605c8e5debe242aebf1/images/58031556869792224.png"
                                            alt=""
                                            width="16"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                        <td
                                          align="left"
                                          style="padding: 0; margin: 0"
                                        >
                                          <table
                                            width="100%"
                                            cellspacing="0"
                                            cellpadding="0"
                                            role="presentation"
                                            style="
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                              border-collapse: collapse;
                                              border-spacing: 0px;
                                            "
                                          >
                                            <tr
                                              style="border-collapse: collapse"
                                            >
                                              <td
                                                align="left"
                                                style="padding: 0; margin: 0"
                                              >
                                                <p
                                                  style="
                                                    margin: 0;
                                                    mso-line-height-rule: exactly;
                                                    font-family: arial,
                                                      'helvetica neue',
                                                      helvetica, sans-serif;
                                                    line-height: 21px;
                                                    letter-spacing: 0;
                                                    color: #333333;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  <a
                                                    target="_blank"
                                                    href="tel:+39095687302"
                                                    style="
                                                      mso-line-height-rule: exactly;
                                                      text-decoration: none;
                                                      color: #333333;
                                                      font-size: 14px;
                                                    "
                                                    >+095687302</a
                                                  >
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-bottom: 5px;
                                            padding-right: 10px;
                                            padding-top: 5px;
                                            font-size: 0;
                                          "
                                        >
                                          <img
                                            src="https://efuobfw.stripocdn.email/content/guids/CABINET_45fbd8c6c971a605c8e5debe242aebf1/images/78111556870146007.png"
                                            alt=""
                                            width="16"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                        <td
                                          align="left"
                                          style="padding: 0; margin: 0"
                                        >
                                          <table
                                            width="100%"
                                            cellspacing="0"
                                            cellpadding="0"
                                            role="presentation"
                                            style="
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                              border-collapse: collapse;
                                              border-spacing: 0px;
                                            "
                                          >
                                            <tr
                                              style="border-collapse: collapse"
                                            >
                                              <td
                                                align="left"
                                                style="padding: 0; margin: 0"
                                              >
                                                <p
                                                  style="
                                                    margin: 0;
                                                    mso-line-height-rule: exactly;
                                                    font-family: arial,
                                                      'helvetica neue',
                                                      helvetica, sans-serif;
                                                    line-height: 21px;
                                                    letter-spacing: 0;
                                                    color: #333333;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  Biancavilla, CT 95033, Italy
                                                </p>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-footer"
              align="center"
              role="none"
              style="
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
                width: 100%;
                table-layout: fixed !important;
                background-color: transparent;
                background-repeat: repeat;
                background-position: center top;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    class="es-footer-body"
                    style="
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #333333;
                      width: 600px;
                    "
                    cellspacing="0"
                    cellpadding="0"
                    bgcolor="#333333"
                    align="center"
                    role="none"
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        style="
                          margin: 0;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-left: 20px;
                          padding-bottom: 15px;
                          background-color: #48bb78;
                        "
                        bgcolor="#48bb78"
                        align="left"
                      >
                        <table
                          width="100%"
                          cellspacing="0"
                          cellpadding="0"
                          role="none"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            border-collapse: collapse;
                            border-spacing: 0px;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              valign="top"
                              align="center"
                              style="padding: 0; margin: 0; width: 560px"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-bottom: 15px;
                                      font-size: 0;
                                    "
                                  >
                                    <table
                                      class="es-table-not-adapt es-social"
                                      cellspacing="0"
                                      cellpadding="0"
                                      role="presentation"
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        border-spacing: 0px;
                                      "
                                    >
                                      <tr style="border-collapse: collapse">
                                        <td
                                          valign="top"
                                          align="center"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-right: 15px;
                                          "
                                        >
                                          <img
                                            title="Facebook"
                                            src="https://efuobfw.stripocdn.email/content/assets/img/social-icons/circle-white/facebook-circle-white.png"
                                            alt="Fb"
                                            width="32"
                                            style="
                                              display: block;
                                              font-size: 14px;
                                              border: 0;
                                              outline: none;
                                              text-decoration: none;
                                            "
                                          />
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="padding: 0; margin: 0"
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        mso-line-height-rule: exactly;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 20px;
                                        letter-spacing: 0;
                                        color: #ffffff;
                                        font-size: 13px;
                                      "
                                    ></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div
      dir="ltr"
      class="es-wrapper-color"
      lang="en"
      style="background-color: #f6f6f6"
    >
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#f6f6f6"></v:fill>
        </v:background>
      <![endif]-->
    </div>
    </div>
  </body>
</html>
`;
};
