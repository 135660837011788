import {
  DeleteHomeCardMutationVariables,
  FinalHomeCard,
  FinalHomeCardInput,
  GetCardByCardIdQueryVariables,
  GetHomeCardsQuery,
  UpdateHomeCardMutationVariables,
} from "../../API";
import { getCardByCardId, getHomeCards } from "../../graphql/queries";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import { FinalHomeCardSchema } from "models/banners";
import { deleteHomeCard, updateHomeCard } from "graphql/mutations";

interface BannerApiInterface {
  getBanners: () => Promise<FinalHomeCard[]>;
  getBanner: (i: { homeCardId: string }) => Promise<FinalHomeCard>;
  updateBanner: (i: { homeCard: FinalHomeCardInput }) => Promise<FinalHomeCard>;
  deleteBanner: (i: { homeCardId: string }) => Promise<FinalHomeCard>;
}

const GetBanners = z.object({
  data: z.object({
    getHomeCards: z.array(FinalHomeCardSchema),
  }),
});

const GetBanner = z.object({
  data: z.object({
    getCardByCardId: FinalHomeCardSchema,
  }),
});

const UpdateBannerSchema = z.object({
  data: z.object({
    updateHomeCard: FinalHomeCardSchema,
  }),
});

const DeleteBannerSchema = z.object({
  data: z.object({
    deleteHomeCard: FinalHomeCardSchema,
  }),
});

export class BannerApi implements BannerApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getBanners = async () => {
    try {
      const queryResult = await this.api.query<GetHomeCardsQuery>({
        query: getHomeCards,
      });

      const result = GetBanners.parse(queryResult);
      return result.data.getHomeCards;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  getBanner = async (i: { homeCardId: string }) => {
    try {
      const queryResult = await this.api.query<GetCardByCardIdQueryVariables>({
        query: getCardByCardId,
        variables: i,
      });

      const result = GetBanner.parse(queryResult);
      return result.data.getCardByCardId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateBanner = async (i: { homeCard: FinalHomeCardInput }) => {
    try {
      const queryResult = await this.api.query<UpdateHomeCardMutationVariables>(
        {
          query: updateHomeCard,
          variables: i,
        }
      );

      const result = UpdateBannerSchema.parse(queryResult);
      return result.data.updateHomeCard;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  deleteBanner = async (i: { homeCardId: string }) => {
    try {
      const queryResult = await this.api.query<DeleteHomeCardMutationVariables>(
        {
          query: deleteHomeCard,
          variables: i,
        }
      );

      const result = DeleteBannerSchema.parse(queryResult);
      return result.data.deleteHomeCard;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const bannerApi = new BannerApi(api);
