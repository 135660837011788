// Chakra imports
import { Flex } from "@chakra-ui/react";

interface AuthIllustrationProps {
  children: React.ReactNode;
}

function AuthIllustration({ children }: AuthIllustrationProps) {
  // Chakra color mode
  return (
    <Flex
      h={{
        sm: "initial",
        md: "unset",
        lg: "100vh",
        xl: "97vh",
      }}
      w="100%"
      maxW={{ md: "66%", lg: "1313px" }}
      mx="auto"
      pt={{ sm: "50px", md: "0px" }}
      px={{ lg: "30px", xl: "0px" }}
      ps={{ xl: "70px" }}
      justifyContent="center"
      align="center"
      direction="column"
    >
      {children}
    </Flex>
  );
}

export default AuthIllustration;
