import { Product } from "API";

export const formatPrice = (price: number) => {
  const cent = price / 100;
  return cent.toFixed(2);
};

export const calculatePrice = (product: Product, hasDiscount: boolean) => {
  const selectedQuantity =
    product.selectedQuantity === 0 ? 1 : product.selectedQuantity;
  const price =
    hasDiscount && product.discount
      ? product.discount.discountPrice
      : product.price;
  return formatPrice(selectedQuantity * price);
};
