import {
  Box,
  Button,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { EmailInput } from "API";
import InputField from "components/fields/InputField";
import { newsLetter } from "prototypes/newsletter/newsletter";
import { useState } from "react";
import { UserAttributes, authApi } from "services/auth-api/auth-api";
import { emailApi } from "services/send-email-api/send-email-api";
import { v4 as uuidv4 } from "uuid";

export interface NewsletterProps {
  title: {
    ita: string;
    eng: string;
  };
  body: {
    ita: string;
    eng: string;
  };
}

const defaultNewsletter: NewsletterProps = {
  title: {
    ita: "Ciao",
    eng: "Hello",
  },
  body: {
    ita: "Ciao body",
    eng: "Hello body",
  },
};

export const Newsletter = () => {
  const toast = useToast();
  const [message, setMessage] = useState<NewsletterProps>(defaultNewsletter);
  const [customersNews, setCustomersNews] = useState<UserAttributes[]>([]);

  const [customers, setCustomers] = useState<string[]>([]);

  const { mutate: sendEmailMutate, isLoading: mutateSendEmailIsLoading } =
    useMutation(
      ["sendEmail", message],
      async () => {
        const users = customers.map(async (user) => {
          const result = await authApi.listUsers(user);
          return result;
        });

        const customersResult = await Promise.all(users);

        const customersFiltered = customersResult.filter(
          (c) => c["custom:newsletter"] === "true"
        );

        const customersFilteredNewsFalse = customersResult.filter(
          (c) => c["custom:newsletter"] === "false"
        );

        if (customersFiltered.length === 0) {
          toast({
            title: "No customers found.",
            description: "No customers found with newsletter enabled.",
            status: "error",
          });
          return customersFilteredNewsFalse;
        }

        const emailInput: EmailInput = {
          emailId: uuidv4(),
          destinations: customersFiltered.map((c) => c.email),
          html: newsLetter(message),
          text: "",
          subject: "Newsletter",
          source: "news@oleificiocuscuna.com",
        };

        await emailApi.sendEmail({
          email: emailInput,
        });
        return customersFilteredNewsFalse;
      },
      {
        onSuccess: (response) => {
          if (response?.length === 0) {
            toast({
              title: "Email sent.",
              description: "Email sent to all customers.",
              status: "success",
            });
          } else {
            toast({
              title: "Email sent.",
              description: "Email sent to some customers.",
              status: "warning",
            });
            console.log(response);
            setCustomersNews(response ?? []);
          }
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  if (mutateSendEmailIsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={2} spacing={10}>
        <Box>
          <InputField
            id="Customers Usernames"
            label="Customers Usernames"
            extra={<></>}
            placeholder="Customers"
            type="text"
            mb="30px"
            value={customers.length > 0 ? customers.join(", ") : ""}
            onChange={(e) =>
              setCustomers(e.target.value.split(",").map((u) => u.trim()))
            }
          />
          <InputField
            id="titleIta"
            label="Title ita"
            extra={<></>}
            placeholder="Title ita"
            type="text"
            mb="30px"
            value={message.title.ita}
            onChange={(e) => {
              setMessage({
                ...message,
                title: { ita: e.target.value, eng: message.title.eng },
              });
            }}
          />
          <InputField
            id="titleEn"
            label="Title en"
            extra={<></>}
            placeholder="Title en"
            type="text"
            mb="30px"
            value={message.title.eng}
            onChange={(e) => {
              setMessage({
                ...message,
                title: { ita: message.title.ita, eng: e.target.value },
              });
            }}
          />
          <InputField
            id="bodyIta"
            label="Body ita"
            extra={<></>}
            placeholder="Body ita"
            type="text"
            mb="30px"
            value={message.body.ita}
            onChange={(e) => {
              setMessage({
                ...message,
                body: { ita: e.target.value, eng: message.body.eng },
              });
            }}
          />
          <InputField
            id="bodyEn"
            label="Body en"
            extra={<></>}
            placeholder="Body en"
            type="text"
            mb="30px"
            value={message.body.eng}
            onChange={(e) => {
              setMessage({
                ...message,
                body: { ita: message.body.ita, eng: e.target.value },
              });
            }}
          />
          <Button onClick={() => sendEmailMutate()}>
            <Text>SEND EMAIL</Text>
          </Button>
          {customersNews.length > 0 && (
            <Box>
              <Text>Customers with newsletter disabled:</Text>
              <Text>{customersNews.map((c) => c.email).join(", ")}</Text>
            </Box>
          )}
        </Box>
        <Box>
          <div dangerouslySetInnerHTML={{ __html: newsLetter(message) }} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};
