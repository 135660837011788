import { NewsletterProps } from "pages/newsletter/newsletter";

const { REACT_APP_CDN, REACT_APP_ENV } = process.env;

export const newsLetter = (news: NewsletterProps) => {
  const defaultImage = `${REACT_APP_CDN}/default_image.png`;

  const base_url =
    REACT_APP_ENV === "development"
      ? "https://www.app.dev.oleificiocuscuna.com"
      : "https://www.oleificiocuscuna.com";

  const newsletterTemplate = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Newsletter</title>
</head>
<body style="margin: 0; padding: 0; font-family: Arial, sans-serif;">
  <div>
        <table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 10px 0;">
                    <img src="${defaultImage}" alt="Newsletter Image" width="300" style="display: block; border: 0;"/>
                </td>
            </tr>
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 10px 0;">
                    <h2 style="margin: 0; font-size: 24px; color: #ffffff;">${news.title.eng}</h2>
                </td>
            </tr>
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 20px;">
                    <p style="margin: 0; font-size: 16px; color: #ffffff;">${news.body.eng}</p>
                </td>
            </tr>
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 20px;">
                    <p style="margin: 0; font-size: 14px; color: #ffffff;">
                        <a href="${base_url}" style="color: #ffffff; text-decoration: none;">Visita il nostro sito</a> |
                        <a href="https://facebook.com/yourpage" style="color: #ffffff; text-decoration: none;">Facebook</a> |
                        <a href="https://instagram.com/yourpage" style="color: #ffffff; text-decoration: none;">Instagram</a>
                    </p>
                </td>
            </tr>
        </table>
        <hr style="border: 1px solid dashed"/>
        <table cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 10px 0;">
                    <img src="${defaultImage}" alt="Newsletter Image" width="300" style="display: block; border: 0;"/>
                </td>
            </tr>
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 10px 0;">
                    <h2 style="margin: 0; font-size: 24px; color: #ffffff;">${news.title.ita}</h2>
                </td>
            </tr>
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 20px;">
                    <p style="margin: 0; font-size: 16px; color: #ffffff;">${news.body.ita}</p>
                </td>
            </tr>
            <tr>
                <td align="center" bgcolor="#48bb78" style="padding: 20px;">
                    <p style="margin: 0; font-size: 14px; color: #ffffff;">
                        <a href="${base_url}" style="color: #ffffff; text-decoration: none;">Visit our site</a> |
                        <a href="https://facebook.com/yourpage" style="color: #ffffff; text-decoration: none;">Facebook</a> |
                        <a href="https://instagram.com/yourpage" style="color: #ffffff; text-decoration: none;">Instagram</a>
                    </p>
                </td>
            </tr>
        </table>
      </div>
      </body>
</html>
  `;

  return newsletterTemplate;
};
