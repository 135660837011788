import {
  DeleteBoxMutation,
  FinalBox,
  FinalBoxInput,
  GetBoxByBoxIdQuery,
  GetBoxesQuery,
  UpdateBoxMutation,
} from "../../API";
import { getBoxByBoxId, getBoxes } from "../../graphql/queries";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { deleteBox, updateBox } from "graphql/mutations";

interface BannerApiInterface {
  getBoxes: () => Promise<FinalBox[]>;
  getBox: (i: { boxId: string }) => Promise<FinalBox>;
  updateBox: (i: { box: FinalBoxInput }) => Promise<FinalBox>;
  deleteBox: (i: { boxId: string; bannerId: string }) => Promise<FinalBox>;
}

export class BoxApi implements BannerApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getBoxes = async () => {
    try {
      const queryResult = await this.api.query<GetBoxesQuery>({
        query: getBoxes,
      });

      return queryResult?.data?.getBoxes || [];
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  getBox = async (i: { boxId: string }) => {
    try {
      const queryResult = await this.api.query<GetBoxByBoxIdQuery>({
        query: getBoxByBoxId,
        variables: i,
      });

      return queryResult?.data?.getBoxByBoxId as FinalBox;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateBox = async (i: { box: FinalBoxInput }) => {
    try {
      const queryResult = await this.api.query<UpdateBoxMutation>({
        query: updateBox,
        variables: i,
      });

      return queryResult?.data?.updateBox as FinalBox;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  deleteBox = async (i: { boxId: string; bannerId: string }) => {
    try {
      const queryResult = await this.api.query<DeleteBoxMutation>({
        query: deleteBox,
        variables: i,
      });

      return queryResult?.data?.deleteBox as FinalBox;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const boxApi = new BoxApi(api);
