import {
  Box,
  Button,
  SimpleGrid,
  Spinner,
  Text as ChakraText,
  useToast,
  Textarea,
  Checkbox,
  Stack,
} from "@chakra-ui/react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useMutation } from "@tanstack/react-query";
import { EmailInput } from "API";
import InputField from "components/fields/InputField";
import { Images } from "images";
import { newsLetter } from "prototypes/newsletter/newsletter";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { UserAttributes } from "services/auth-api/auth-api";
import { emailApi } from "services/send-email-api/send-email-api";
import { v4 as uuidv4 } from "uuid";

export interface NewsletterProps {
  title: {
    ita: string;
    eng: string;
  };
  body: {
    ita: string;
    eng: string;
  };
}

export const OrderForm = () => {
  const toast = useToast();
  const location = useLocation();
  const message = location.state as {
    message: NewsletterProps;
  };
  const [myDocument, setMyDocument] = useState<MyDocumentProps>({
    orderDetails: "",
    paymentTerms: {
      thirtyDays: {
        isPresent: false,
        description:
          "Pagamento entro 30 giorni dalla data di emissione della fattura / Payment within 30 days from the date of invoice issuance.",
      },
      sixtyDays: {
        isPresent: false,
        description:
          "Pagamento entro 60 giorni dalla data di emissione della fattura / Payment within 60 days from the date of invoice issuance.",
      },
      account: {
        isPresent: false,
        description:
          "Acconto del ______% al momento dell'ordine, saldo entro ____ giorni dalla data di emissione della fattura / Deposit of ______% upon order, balance within ____ days from the date of invoice issuance.",
      },
      other: {
        isPresent: false,
        description: "Altro (specificare) / Other (please specify):",
      },
    },
  });

  const { mutate: sendEmailMutate, isLoading: mutateSendEmailIsLoading } =
    useMutation(
      ["sendInvoiceEmail", message],
      async () => {
        const emailInput: EmailInput = {
          emailId: uuidv4(),
          destinations: ["sales@oleificiocuscuna.com"],
          html: newsLetter(message.message),
          text: "",
          subject: "Invoice",
          source: "sales@oleificiocuscuna.com",
        };

        await emailApi.sendEmail({
          email: emailInput,
        });
        return "sales@oleificiocuscuna.com";
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Email sent.",
            description: "Email sent to all customers.",
            status: "success",
          });
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  if (mutateSendEmailIsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  const handleCheckboxChange = (type: string, isChecked: boolean) => {
    setMyDocument((prevState) => ({
      ...prevState,
      paymentTerms: {
        ...prevState.paymentTerms,
        [type as keyof typeof prevState.paymentTerms]: {
          ...prevState.paymentTerms[
            type as keyof typeof prevState.paymentTerms
          ],
          isPresent: isChecked,
        },
      },
    }));
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={2} spacing={20}>
        <Box>
          <Textarea
            placeholder="Order details"
            value={myDocument.orderDetails}
            onChange={(e) => {
              setMyDocument({ ...myDocument, orderDetails: e.target.value });
            }}
          />
          <Stack>
            <Checkbox
              mt={30}
              isChecked={myDocument.paymentTerms.thirtyDays.isPresent}
              onChange={(e) =>
                handleCheckboxChange("thirtyDays", e.target.checked)
              }
            >
              Thirty Days
            </Checkbox>
            <Textarea
              id="thirtyDays"
              placeholder="Description"
              mb="30px"
              value={myDocument.paymentTerms.thirtyDays.description}
              onChange={(e) => {
                setMyDocument((prevState) => ({
                  ...prevState,
                  paymentTerms: {
                    ...prevState.paymentTerms,
                    thirtyDays: {
                      ...prevState.paymentTerms.thirtyDays,
                      description: e.target.value,
                    },
                  },
                }));
              }}
            />
            <Checkbox
              mt={30}
              isChecked={myDocument.paymentTerms.sixtyDays.isPresent}
              onChange={(e) =>
                handleCheckboxChange("sixtyDays", e.target.checked)
              }
            >
              Sixty Days
            </Checkbox>
            <Textarea
              id="sixtyDays"
              placeholder="Description"
              mb="30px"
              value={myDocument.paymentTerms.sixtyDays.description}
              onChange={(e) => {
                setMyDocument((prevState) => ({
                  ...prevState,
                  paymentTerms: {
                    ...prevState.paymentTerms,
                    sixtyDays: {
                      ...prevState.paymentTerms.sixtyDays,
                      description: e.target.value,
                    },
                  },
                }));
              }}
            />
            <Checkbox
              mt={30}
              isChecked={myDocument.paymentTerms.account.isPresent}
              onChange={(e) =>
                handleCheckboxChange("account", e.target.checked)
              }
            >
              Account
            </Checkbox>
            <Textarea
              id="account"
              placeholder="Description"
              h={200}
              mb="30px"
              value={myDocument.paymentTerms.account.description}
              onChange={(e) => {
                setMyDocument((prevState) => ({
                  ...prevState,
                  paymentTerms: {
                    ...prevState.paymentTerms,
                    account: {
                      ...prevState.paymentTerms.account,
                      description: e.target.value,
                    },
                  },
                }));
              }}
            />
            <Checkbox
              mt={30}
              isChecked={myDocument.paymentTerms.other.isPresent}
              onChange={(e) => handleCheckboxChange("other", e.target.checked)}
            >
              Other
            </Checkbox>
            <Textarea
              id="other"
              placeholder="Description"
              mb="30px"
              value={myDocument.paymentTerms.other.description}
              onChange={(e) => {
                setMyDocument((prevState) => ({
                  ...prevState,
                  paymentTerms: {
                    ...prevState.paymentTerms,
                    other: {
                      ...prevState.paymentTerms.other,
                      description: e.target.value,
                    },
                  },
                }));
              }}
            />
          </Stack>
          <Button mt="20px" onClick={() => sendEmailMutate()}>
            <ChakraText>SEND EMAIL</ChakraText>
          </Button>
        </Box>
        <Box display="flex">
          <PDFViewer
            style={{
              width: "100%",
              height: "100vh",
            }}
            showToolbar={true}
          >
            <MyDocument myDocument={myDocument} />
          </PDFViewer>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

const styles = StyleSheet.create({
  image: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    opacity: 0.1,
  },
  topRightText: {
    position: "absolute",
    top: "5%",
    right: "16%",
    left: "16%",
    marginHorizontal: "auto",
    textAlign: "center",
    justifyContent: "center",
    border: "2px solid green",
    borderRadius: "8px",
    padding: "8px",
  },
  centerText: {
    position: "absolute",
    top: "12%",
    width: "100%",
    padding: "16px",
  },
  centerText2: {
    position: "absolute",
    top: "5%",
    width: "100%",
    padding: "16px",
  },
  bottomText: {
    position: "absolute",
    width: "100%",
    top: "35%",
    padding: "16px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  box: {
    width: 10,
    height: 10,
    margin: 5,
    borderWidth: 1,
    marginRight: 5,
  },
  page: {
    flexDirection: "row",
    margin: 10,
    width: "90%",
  },
});

export interface MyDocumentProps {
  orderDetails: string;
  paymentTerms: {
    thirtyDays: {
      isPresent: boolean;
      description: string;
    };
    sixtyDays: {
      isPresent: boolean;
      description: string;
    };
    account: {
      isPresent: boolean;
      description: string;
    };
    other: {
      isPresent: boolean;
      description: string;
    };
  };
}

interface MyDocumentPDFProps {
  myDocument: MyDocumentProps;
}

const MyDocument: React.FC<MyDocumentPDFProps> = (props) => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.image}>
          <Image src={Images.Logo} />
        </View>
        <View style={styles.topRightText}>
          <Text
            style={{
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Modulo di Ordine / Order Form{" "}
          </Text>
        </View>
        <View style={styles.centerText}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            Dati del Cliente / Customer Information:
          </Text>

          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            {"\u2022"} Nome dell'Azienda/Cliente /{"\n"} Company/Client Name:
            .....................
          </Text>

          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            {"\u2022"} Indirizzo / Address: .....................
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            {"\u2022"} Città / City: .....................
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            {"\u2022"} CAP / Postal Code: .....................
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            {"\u2022"} Telefono / Phone: .....................
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            {"\u2022"} Email: .....................
          </Text>
        </View>
        <View style={styles.bottomText}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              marginTop: "24px",
            }}
          >
            Dettagli dell'Ordine / Order Details:
          </Text>

          {/* 
              Prodotto (specificare quantità, confezione, etc.) / Product (specify quantity, packaging, etc.): ___________________
              Quantità / Quantity: _______________________
              Prezzo unitario / Unit Price: ___________________
              Importo totale / Total Amount: ___________________
           */}

          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            {props.myDocument.orderDetails}
          </Text>
        </View>
      </Page>
      <Page size="A4">
        <View style={styles.image}>
          <Image src={Images.Logo} />
        </View>
        <View style={styles.centerText2}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              marginTop: "24px",
            }}
          >
            Termini di Pagamento / Payment Terms:
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            Il sottoscritto dichiara di aver preso visione e accettato i
            seguenti termini di pagamento / The undersigned declares to have
            read and accepted the following payment terms:
          </Text>
          {props.myDocument.paymentTerms.thirtyDays.isPresent && (
            <View style={styles.page}>
              <View style={styles.box}></View>
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                {props.myDocument.paymentTerms.thirtyDays.description}
              </Text>
            </View>
          )}
          {props.myDocument.paymentTerms.sixtyDays.isPresent && (
            <View style={styles.page}>
              <View style={styles.box}></View>
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                {props.myDocument.paymentTerms.sixtyDays.description}
              </Text>
            </View>
          )}
          {props.myDocument.paymentTerms.account.isPresent && (
            <View style={styles.page}>
              <View style={styles.box}></View>
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                {props.myDocument.paymentTerms.account.description}
              </Text>
            </View>
          )}
          {props.myDocument.paymentTerms.other.isPresent && (
            <View style={styles.page}>
              <View style={styles.box}></View>
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                {props.myDocument.paymentTerms.other.description}
              </Text>
            </View>
          )}
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              marginTop: "24px",
            }}
          >
            Firma del Cliente / Customer Signature:
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            Firmo il presente modulo di ordine confermando la mia accettazione
            dei termini sopra indicati / I hereby sign this order form
            confirming my acceptance of the above terms.
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            Firma / Signature: _________________________
          </Text>
          <Text
            style={{
              fontSize: 16,
              marginTop: "8px",
            }}
          >
            Data / Date: _______________________
          </Text>
        </View>
      </Page>
    </Document>
  );
};
