import { Box, Button, Spinner, Text, SimpleGrid } from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import OrderTable from "components/tables/OrderTable";
import React from "react";
import { orderApi } from "services/order-api/order-api";

const fetchPaginatedData = async ({
  pageParam = undefined,
  status,
}: {
  pageParam?: string;
  status: string;
}) => {
  const nextToken = pageParam ? pageParam : undefined;
  return orderApi.getAllFinalOrdersBack({
    status,
    limit: 10,
    nextToken,
  });
};

interface Order {
  orderId: string;
  orderName: string;
  recipient: string;
  address: string;
  startDate: string;
  button: string;
}

export const Orders: React.FC = () => {
  const [status, setStatus] = React.useState("");
  const [orders, setOrders] = React.useState<Order[]>([]);
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading } =
    useInfiniteQuery(
      ["getAllFinalOrdersBack", status],
      ({ pageParam }) => fetchPaginatedData({ pageParam, status }),
      {
        enabled: !!status,
        getNextPageParam: (lastPage, pages) => {
          if (lastPage.nextToken) {
            return lastPage.nextToken;
          }
          return undefined;
        },
        onSuccess(data) {
          data.pages.forEach((page) => {
            page.items.forEach((order) => {
              // check if the status is changed, then reset the orders
              if (status !== order.status) {
                setOrders([]);
              }
              // check if the order is already in the list
              const orderExists = orders.find(
                (o) => o.orderId === order.orderId
              );
              if (orderExists) return;
              setOrders((prev) => [
                ...prev,
                {
                  orderId: order.orderId,
                  orderName: order.orderName,
                  recipient: order.address.recipient,
                  address: order.address.formattedAddress,
                  startDate: order.startDate,
                  button: order.orderId,
                },
              ]);
            });
          });
        },
      }
    );

  if (isLoading && !!status) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Button
        mr={2}
        mb={2}
        onClick={() => {
          setStatus("ARRIVED");
          setOrders([]);
        }}
        color={status === "ARRIVED" ? "teal" : "none"}
        bg={status === "ARRIVED" ? "teal.100" : "none"}
        border="1px solid"
        isDisabled={status === "ARRIVED"}
      >
        <Text>ARRIVED</Text>
      </Button>
      <Button
        mr={2}
        mb={2}
        onClick={() => {
          setStatus("STARTED");
          setOrders([]);
        }}
        color={status === "STARTED" ? "teal" : "none"}
        bg={status === "STARTED" ? "teal.100" : "none"}
        border="1px solid"
        isDisabled={status === "STARTED"}
      >
        <Text>STARTED</Text>
      </Button>
      <Button
        mr={2}
        mb={2}
        onClick={() => {
          setStatus("READY");
          setOrders([]);
        }}
        color={status === "READY" ? "teal" : "none"}
        bg={status === "READY" ? "teal.100" : "none"}
        border="1px solid"
        isDisabled={status === "READY"}
      >
        <Text>READY</Text>
      </Button>
      <Button
        mr={2}
        mb={2}
        onClick={() => {
          setStatus("DELIVERING");
          setOrders([]);
        }}
        color={status === "DELIVERING" ? "teal" : "none"}
        bg={status === "DELIVERING" ? "teal.100" : "none"}
        border="1px solid"
        isDisabled={status === "DELIVERING"}
      >
        <Text>DELIVERING</Text>
      </Button>
      <Button
        mr={2}
        mb={2}
        onClick={() => {
          setStatus("DELIVERED");
          setOrders([]);
        }}
        color={status === "DELIVERED" ? "teal" : "none"}
        bg={status === "DELIVERED" ? "teal.100" : "none"}
        border="1px solid"
        isDisabled={status === "DELIVERED"}
      >
        <Text>DELIVERED</Text>
      </Button>
      <Button
        mr={2}
        mb={2}
        onClick={() => {
          setStatus("CANCELED");
          setOrders([]);
        }}
        color={status === "CANCELED" ? "teal" : "none"}
        bg={status === "CANCELED" ? "teal.100" : "none"}
        border="1px solid"
        isDisabled={status === "CANCELED"}
      >
        <Text>CANCELED</Text>
      </Button>
      <Button
        mb={2}
        onClick={() => {
          setStatus("REJECTED");
          setOrders([]);
        }}
        color={status === "REJECTED" ? "teal" : "none"}
        bg={status === "REJECTED" ? "teal.100" : "none"}
        border="1px solid"
        isDisabled={status === "REJECTED"}
      >
        <Text>REJECTED</Text>
      </Button>

      <SimpleGrid
        mb="20px"
        // columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <OrderTable tableData={orders} />
      </SimpleGrid>
      <Button
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
        alignSelf="center"
        alignContent="center"
        justifyContent="center"
        colorScheme="teal"
        display="flex"
        m="auto"
      >
        {isFetchingNextPage
          ? "Loading more..."
          : hasNextPage
          ? "Load More"
          : "Nothing more to load"}
      </Button>
    </Box>
  );
};
