import { z } from "zod";

export const FFSchema = z.object({
  __typename: z.literal("FF"),
  ff: z.string(),
  ffId: z.string(),
  users: z.array(z.string()).nullable(),
});

export const FFInputSchema = z.object({
  ff: z.string(),
  ffId: z.string(),
  users: z.array(z.string()).nullable(),
});
