import {
  Box,
  Button,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { EmailInput } from "API";
import InputField from "components/fields/InputField";
import { newsLetter } from "prototypes/newsletter/newsletter";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAttributes } from "services/auth-api/auth-api";
import { emailApi } from "services/send-email-api/send-email-api";
import { v4 as uuidv4 } from "uuid";

export interface NewsletterProps {
  title: {
    ita: string;
    eng: string;
  };
  body: {
    ita: string;
    eng: string;
  };
}

const defaultNewsletter: NewsletterProps = {
  title: {
    ita: "Ciao",
    eng: "Hello",
  },
  body: {
    ita: "Ciao body",
    eng: "Hello body",
  },
};

export const Invoicies = () => {
  const toast = useToast();
  const [message, setMessage] = useState<NewsletterProps>(defaultNewsletter);
  const navigate = useNavigate();

  const { mutate: sendEmailMutate, isLoading: mutateSendEmailIsLoading } =
    useMutation(
      ["sendInvoiceEmail", message],
      async () => {
        const emailInput: EmailInput = {
          emailId: uuidv4(),
          destinations: ["sales@oleificiocuscuna.com"],
          html: newsLetter(message),
          text: "",
          subject: "Invoice",
          source: "sales@oleificiocuscuna.com",
        };

        await emailApi.sendEmail({
          email: emailInput,
        });
        return "sales@oleificiocuscuna.com";
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Email sent.",
            description: "Email sent to all customers.",
            status: "success",
          });
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  if (mutateSendEmailIsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={2} spacing={10}>
        <Box>
          <InputField
            id="titleIta"
            label="Title ita"
            extra={<></>}
            placeholder="Title ita"
            type="text"
            mb="30px"
            value={message.title.ita}
            onChange={(e) => {
              setMessage({
                ...message,
                title: { ita: e.target.value, eng: message.title.eng },
              });
            }}
          />
          <InputField
            id="titleEn"
            label="Title en"
            extra={<></>}
            placeholder="Title en"
            type="text"
            mb="30px"
            value={message.title.eng}
            onChange={(e) => {
              setMessage({
                ...message,
                title: { ita: message.title.ita, eng: e.target.value },
              });
            }}
          />
          <InputField
            id="bodyIta"
            label="Body ita"
            extra={<></>}
            placeholder="Body ita"
            type="text"
            mb="30px"
            value={message.body.ita}
            onChange={(e) => {
              setMessage({
                ...message,
                body: { ita: e.target.value, eng: message.body.eng },
              });
            }}
          />
          <InputField
            id="bodyEn"
            label="Body en"
            extra={<></>}
            placeholder="Body en"
            type="text"
            mb="30px"
            value={message.body.eng}
            onChange={(e) => {
              setMessage({
                ...message,
                body: { ita: message.body.ita, eng: e.target.value },
              });
            }}
          />
          <Stack>
            <Button onClick={() => sendEmailMutate()}>
              <Text>SEND EMAIL</Text>
            </Button>
            <Button
              onClick={() =>
                navigate("order-form", {
                  state: { message },
                })
              }
            >
              <Text>Order Form</Text>
            </Button>
          </Stack>
        </Box>
        <Box>
          <div dangerouslySetInnerHTML={{ __html: newsLetter(message) }} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};
