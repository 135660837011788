/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Container,
  Stack,
  Text,
  Flex,
  VStack,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
  Badge,
} from "@chakra-ui/react";

import { Product } from "API";
import { AsyncImage } from "components/async-image/AsyncImage";
import { calculatePrice, formatPrice } from "utils/utils";
import { v4 as uuidv4 } from "uuid";

interface OrderProductInfoProps {
  product: Product;
}

const { REACT_APP_CDN } = process.env;

export default function OrderProductInfo({ product }: OrderProductInfoProps) {
  const path = product.productType.toLowerCase() === "oil" ? "oil" : "olive";
  const image = `${REACT_APP_CDN}/${path}/${product.productId}.png`;

  return (
    <Container maxW={"7xl"}>
      <SimpleGrid>
        <Flex alignItems="center" justifyContent="center">
          <AsyncImage
            src={`${image}?${uuidv4()}`}
            alt={product.name}
            fit={"cover"}
            align={"center"}
            height={200}
          />
        </Flex>
        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={"header"}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
            >
              {product.name}
            </Heading>
            <Box display="flex" alignItems="center">
              <Box
                fontWeight="bold"
                pr={2}
                style={{
                  textDecoration:
                    product.discount && product.discount.discountPrice > 0
                      ? "line-through"
                      : "none",
                  color:
                    product.discount && product.discount.discountPrice > 0
                      ? "gray"
                      : "black",
                }}
              >
                {calculatePrice(product, false)} €
              </Box>
              {product.discount && product.discount.discountPrice > 0 && (
                <Box display="flex" flexDirection="row">
                  <Box fontWeight="bold" pr={2}>
                    {calculatePrice(product, !!product.discount)} €
                  </Box>
                </Box>
              )}
              {product.discount &&
                product.discount.discountPrice > 0 &&
                product.discount?.discountPercentage && (
                  <Badge
                    height={6}
                    borderRadius="2xl"
                    px="2"
                    colorScheme="orange"
                    display="flex"
                    alignItems="center"
                    ml={6}
                  >
                    {-product.discount?.discountPercentage}%
                  </Badge>
                )}
            </Box>
          </Box>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={"column"}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.200", "gray.600")}
              />
            }
          >
            <VStack spacing={{ base: 4, sm: 6 }}>
              <Text
                color={useColorModeValue("gray.500", "gray.400")}
                fontSize={"2xl"}
                fontWeight={"300"}
              >
                {product.description}
              </Text>
            </VStack>
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={useColorModeValue("yellow.500", "yellow.300")}
                fontWeight={"500"}
                textTransform={"uppercase"}
                mb={"4"}
              >
                Features
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  {product.isNew && (
                    <ListItem>
                      <Badge borderRadius="full" px="2" colorScheme="pink">
                        New
                      </Badge>
                    </ListItem>
                  )}
                  {product.certifications &&
                    product.certifications.includes("DOP") && (
                      <ListItem>
                        <Badge borderRadius="full" px="2" colorScheme="blue">
                          DOP
                        </Badge>
                      </ListItem>
                    )}
                  {product.certifications &&
                    product.certifications.includes("IGP") && (
                      <ListItem>
                        <Badge borderRadius="full" px="2" colorScheme="yellow">
                          IGP
                        </Badge>
                      </ListItem>
                    )}
                  {product.certifications &&
                    product.certifications.includes("BIO") && (
                      <ListItem>
                        <Badge borderRadius="full" px="2" colorScheme="green">
                          BIO
                        </Badge>
                      </ListItem>
                    )}
                </List>
                {product.attribute && product.attribute.length > 0 && (
                  <List spacing={2}>
                    {product.attribute.map((attr, index) => (
                      <ListItem key={index}>{attr}</ListItem>
                    ))}
                  </List>
                )}
              </SimpleGrid>
            </Box>
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={useColorModeValue("yellow.500", "yellow.300")}
                fontWeight={"500"}
                textTransform={"uppercase"}
                mb={"4"}
              >
                Product Details
              </Text>

              <List spacing={2}>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Linea:
                  </Text>{" "}
                  {product.line}
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Formato:
                  </Text>{" "}
                  {`(1 pz - ${formatPrice(product.format.unitPrice)} €)`}
                </ListItem>
                <ListItem>
                  <>
                    <Text as={"span"} fontWeight={"bold"}>
                      Formato descrizione:
                    </Text>{" "}
                    {product.format.description ?? "-"}
                  </>
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
