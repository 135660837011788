import { Box, Button, HStack, SimpleGrid, Spinner } from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { SearchBar } from "components/navbar/searchBar/SearchBar";
import CatalogTable from "components/tables/CatalogTable";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { catalogApi } from "services/catalog-api/catalog-api";
import { formatPrice } from "utils/utils";

const fetchPaginatedData = async ({
  pageParam = undefined,
  roleType,
}: {
  pageParam?: string;
  roleType: string;
}) => {
  const nextToken = pageParam ? pageParam : undefined;
  return catalogApi.getCatalogByUserPaginated({
    roleType,
    limit: 10,
    nextToken,
  });
};

interface CatalogApi {
  productId: string;
  productName: string;
  line: string;
  productType: string;
  price: string;
  roleType: string;
  button: string;
}

export const Catalog = () => {
  const [roleType, setRoleType] = useState("standard");
  const [search, setSearch] = useState("standard");
  const [catalog, setCatalog] = useState<CatalogApi[]>([]);
  const navigate = useNavigate();

  const { fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading } =
    useInfiniteQuery(
      ["getCatalogByUserPaginated", roleType],
      ({ pageParam }) => fetchPaginatedData({ pageParam, roleType }),
      {
        enabled: !!roleType,
        getNextPageParam: (lastPage, pages) => {
          if (lastPage.nextToken) {
            return lastPage.nextToken;
          }
          return undefined;
        },
        onSuccess(data) {
          data.pages.forEach((page) => {
            page.items.forEach(async (catalog) => {
              const productWithRoleType =
                await catalogApi.getProductByProductId({
                  productId: catalog.productId || "",
                });

              if (search !== "standard") {
                setCatalog([]);
              }
              setCatalog((prev) => [
                ...prev,
                {
                  productId: catalog.productId,
                  productName: `${catalog.name.ita} - ${catalog.format.description.ita}`,
                  line: catalog.line,
                  productType: catalog.productType,
                  price: `${catalog.format.unitQuantity} pz -> ${formatPrice(
                    catalog.format.unitPrice
                  )} €  - ${formatPrice(catalog.price)} €  `,
                  roleType: productWithRoleType.roleType,
                  button: catalog.productId,
                },
              ]);
            });
          });
        },
      }
    );

  if (isLoading && !!roleType) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <HStack mb={2}>
        <Button
          onClick={() => {
            setRoleType("standard");
            setSearch("standard");
            setCatalog([]);
          }}
          isDisabled={roleType === "standard"}
        >
          STANDARD
        </Button>
        <Button
          onClick={() => {
            const finalSearch = `${search}`;
            setRoleType(finalSearch);
            setCatalog([]);
          }}
          isDisabled={roleType === search}
        >
          USER
        </Button>
        <SearchBar
          mb={() => {
            return "unset";
          }}
          flex={1}
          type="text"
          value={search}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setSearch(e.target.value)
          }
          me="10px"
          borderRadius="30px"
        />
        <Button onClick={() => navigate("/catalog/new-product")}>ADD</Button>
      </HStack>
      <SimpleGrid
        mb="20px"
        // columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <CatalogTable tableData={catalog} />
      </SimpleGrid>
      <Button
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
        alignSelf="center"
        alignContent="center"
        justifyContent="center"
        colorScheme="teal"
        display="flex"
        m="auto"
      >
        {isFetchingNextPage
          ? "Loading more..."
          : hasNextPage
          ? "Load More"
          : "Nothing more to load"}
      </Button>
    </Box>
  );
};
