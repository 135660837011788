import { API } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import { Storage } from "@aws-amplify/storage";

const {
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  REACT_APP_CDN_BUCKET,
} = process.env;

export function setup() {
  Auth.configure({
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
  });
  API.configure({
    aws_appsync_authenticationType: REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
    aws_appsync_graphqlEndpoint: REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: REACT_APP_REGION,
  });

  Storage.configure({
    bucket: REACT_APP_CDN_BUCKET,
    region: REACT_APP_REGION,
  });
}
