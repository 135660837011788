import { deleteFF, updateFF } from "graphql/mutations";
import {
  GetFFByUserIdQuery,
  FF,
  GetFFByFFIdQueryVariables,
  FFInput,
  UpdateFFMutationVariables,
  DeleteFFMutationVariables,
} from "../../API";
import { getFFByFFId, getFFs } from "../../graphql/queries";
import { FFSchema } from "../../models/ff/ff";
import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";

interface FFApiInterface {
  getFFs: () => Promise<FF[]>;
  getFFByFFId: (ffId: string) => Promise<FF>;
  updateFF: (ff: FFInput) => Promise<FF>;
  deleteFF: (ffId: string) => Promise<FF>;
}

const GetFFByUserIdQuerySchema = z.object({
  data: z.object({
    getFFs: z.array(FFSchema),
  }),
});

const GetFFByFFIdQuerySchema = z.object({
  data: z.object({
    getFFByFFId: FFSchema,
  }),
});

const UpdateFFMutationSchema = z.object({
  data: z.object({
    updateFF: FFSchema,
  }),
});

const DeleteFFMutationSchema = z.object({
  data: z.object({
    deleteFF: FFSchema,
  }),
});

export class FFApi implements FFApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getFFs = async () => {
    try {
      // Fetch the data using your API
      const queryResult = await this.api.query<GetFFByUserIdQuery>({
        query: getFFs,
      });

      // Validate the query result using Zod
      const validatedData = GetFFByUserIdQuerySchema.parse(queryResult);

      return validatedData.data.getFFs;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  getFFByFFId = async (ffId: string) => {
    try {
      // Fetch the data using your API
      const queryResult = await this.api.query<GetFFByFFIdQueryVariables>({
        query: getFFByFFId,
        variables: { ffId },
      });

      // Validate the query result using Zod
      const validatedData = GetFFByFFIdQuerySchema.parse(queryResult);

      return validatedData.data.getFFByFFId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateFF = async (ff: FFInput) => {
    try {
      // Fetch the data using your API
      const mutationResult = await this.api.query<UpdateFFMutationVariables>({
        query: updateFF,
        variables: { ff },
      });

      // Validate the query result using Zod
      const validatedData = UpdateFFMutationSchema.parse(mutationResult);

      return validatedData.data.updateFF;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  deleteFF = async (ffId: string) => {
    try {
      // Fetch the data using your API
      const mutationResult = await this.api.query<DeleteFFMutationVariables>({
        query: deleteFF,
        variables: { ffId },
      });

      // Validate the query result using Zod
      const validatedData = DeleteFFMutationSchema.parse(mutationResult);

      return validatedData.data.deleteFF;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const ffApi = new FFApi(api);
