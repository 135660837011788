import { z } from "zod";

export type STATUS =
  | "CREATED"
  | "ARRIVED"
  | "STARTED"
  | "READY"
  | "DELIVERING"
  | "DELIVERED"
  | "CANCELED"
  | "REJECTED";

export const PaymentSchema = z.object({
  __typename: z.literal("Payment"),
  method: z.string(),
  status: z.string(),
  totalProductsPrice: z.number(),
  totalShipping: z.number(),
  totalPrice: z.number(),
  paymentId: z.string(),
  creditCard: z.union([
    z.object({
      __typename: z.literal("CreditCard"),
      cardNumber: z.string(),
      cardHolder: z.string(),
      expirationDate: z.string(),
      cvv: z.string(),
    }),
    z.null(),
  ]),
});

export const PaymentInputSchema = z.object({
  method: z.string(),
  status: z.string(),
  totalProductsPrice: z.number(),
  totalShipping: z.number(),
  totalPrice: z.number(),
  paymentId: z.string(),
  creditCard: z.union([
    z.object({
      cardNumber: z.string(),
      cardHolder: z.string(),
      expirationDate: z.string(),
      cvv: z.string(),
    }),
    z.null(),
  ]),
});

export const AddressSchema = z.object({
  __typename: z.literal("Address"),
  street: z.string(),
  number: z.string(),
  email: z.string(),
  city: z.string(),
  region: z.string(),
  zipCode: z.string(),
  country: z.string(),
  recipient: z.string(),
  formattedAddress: z.string(),
  lat: z.string(),
  lng: z.string(),
  info: z.string(),
  addressId: z.string(),
  userId: z.string(),
  isDefault: z.boolean(),
  phone: z.string(),
});

export const AddressInputSchema = z.object({
  street: z.string(),
  number: z.string(),
  city: z.string(),
  email: z.string(),
  region: z.string(),
  zipCode: z.string(),
  country: z.string(),
  recipient: z.string(),
  formattedAddress: z.string(),
  lat: z.string(),
  lng: z.string(),
  info: z.string(),
  addressId: z.string(),
  userId: z.string(),
  isDefault: z.boolean(),
  phone: z.string(),
});

export const DiscountInputSchema = z.object({
  discountPercentage: z.number(),
  discountPrice: z.number(),
});

export const FormatInputSchema = z.object({
  unit: z.string(), // l, ml, kg, g, other
  unitPrice: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  unitQuantity: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  formatType: z.string(), // fusti
  formatQuantity: z.number(), // 2
  boxType: z.string(), // pedana
  boxTypeQuantity: z.number(), // 1
  boxWeight: z.number(), // 1
  description: z.string(), // string
});

export const ProductInputSchema = z.object({
  isThisYear: z.boolean(), // oil or olive from this campaign
  line: z.string(), //Natale, Simone, Cuscunà, etc
  price: z.number(), // price per unit
  certifications: z.array(z.string()), //bio, dop, etc
  attribute: z.array(z.string()), //fruttato, delicato, etc
  productId: z.string(), // unique id
  description: z.string(), // description of the product
  productType: z.string(), //oil, olive, etc
  sizeType: z.union([z.string(), z.null()]), // only for olive (00, 01, 02, 03, 04, 05)
  name: z.string(), // Natatle, Simone, Cuscunà, etc
  isNew: z.boolean(), // is new product
  isSoldOut: z.boolean(), // is sold out
  users: z.union([z.array(z.string()), z.null()]),
  discount: z.union([DiscountInputSchema, z.null()]), // discount if present
  format: FormatInputSchema, // format of the product
  selectedQuantity: z.number(), // quantity selected by the user
});

export const DiscountSchema = z.object({
  __typename: z.literal("Discount"),
  discountPercentage: z.number(),
  discountPrice: z.number(),
});

export const ArrayProductInputSchema = z.array(ProductInputSchema);

// unit = kg
// unitQuantity = 130
// formatType = fusti
// formatQuantity = 2
// boxType = pedana
// boxTypeQuantity = 1
// description = "string"

export const FormatSchema = z.object({
  __typename: z.literal("Format"),
  unit: z.string(), // l, ml, kg, g, other
  unitPrice: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  unitQuantity: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  formatType: z.string(), // fusti
  formatQuantity: z.number(), // 2
  boxType: z.string(), // pedana
  boxTypeQuantity: z.number(), // 1
  boxWeight: z.number(), // 1
  description: z.string(), // string
});

export const ProductSchema = z.object({
  __typename: z.literal("Product"),
  isThisYear: z.boolean(), // oil or olive from this campaign
  line: z.string(), //Natale, Simone, Cuscunà, etc
  price: z.number(), // price per unit
  certifications: z.array(z.string()), //bio, dop, etc
  attribute: z.array(z.string()), //fruttato, delicato, etc
  productId: z.string(), // unique id
  description: z.string(), // description of the product
  productType: z.string(), //oil, olive, etc
  sizeType: z.union([z.string(), z.null()]), // only for olive (00, 01, 02, 03, 04, 05)
  name: z.string(), // Natatle, Simone, Cuscunà, etc
  isNew: z.boolean(), // is new product
  isSoldOut: z.boolean(), // is sold out
  users: z.union([z.array(z.string()), z.null()]),
  discount: z.union([DiscountSchema, z.null()]), // discount if present
  format: FormatSchema, // format of the product
  selectedQuantity: z.number(), // quantity selected by the user
});

export const OrderSchema = z.object({
  __typename: z.literal("Order"),
  orderId: z.string(),
  userId: z.string(),
  status: z.union([
    z.literal("CREATED"),
    z.literal("ARRIVED"),
    z.literal("STARTED"),
    z.literal("READY"),
    z.literal("DELIVERING"),
    z.literal("DELIVERED"),
    z.literal("CANCELED"),
    z.literal("REJECTED"),
  ]),
  role: z.string(),
  orderName: z.string(),
  startDate: z.string(),
  estimateDate: z.string(),
  endDate: z.string(),
  tracking: z.array(
    z.object({
      __typename: z.literal("Tracking"),
      update: z.string(),
      // stase can be one of the following: "ARRIVED" | "STARTED" | "READY" | "DELIVERING" | "DELIVERED" | "CANCELED" | "REJECTED"
      status: z.union([
        z.literal("CREATED"),
        z.literal("ARRIVED"),
        z.literal("STARTED"),
        z.literal("READY"),
        z.literal("DELIVERING"),
        z.literal("DELIVERED"),
        z.literal("CANCELED"),
        z.literal("REJECTED"),
      ]),
    })
  ),
  info: z.string(),
  payment: PaymentSchema,
  address: AddressSchema,
  products: z.array(ProductSchema),
});

export const DescriptionSchema = z.object({
  __typename: z.literal("Description"),
  ita: z.string(),
  en: z.string(),
});

export const FinalFormatSchema = z.object({
  __typename: z.literal("FinalFormat"),
  unit: z.string(), // l, ml, kg, g, other
  unitPrice: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  unitQuantity: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  formatType: z.string(), // fusti
  formatQuantity: z.number(), // 2
  boxType: z.string(), // pedana
  boxTypeQuantity: z.number(), // 1
  boxWeight: z.number(), // 1
  description: DescriptionSchema, // string
});

export const FinalProductSchema = z.object({
  __typename: z.literal("FinalProduct"),
  isThisYear: z.boolean(), // oil or olive from this campaign
  line: z.string(), //Natale, Simone, Cuscunà, etc
  price: z.number(), // price per unit
  certifications: z.array(z.string()), //bio, dop, etc
  attribute: z.array(z.string()), //fruttato, delicato, etc
  productId: z.string(), // unique id
  description: DescriptionSchema, // description of the product
  productType: z.string(), //oil, olive, etc
  sizeType: z.union([z.string(), z.null()]), // only for olive (00, 01, 02, 03, 04, 05)
  name: DescriptionSchema, // Natatle, Simone, Cuscunà, etc
  isNew: z.boolean(), // is new product
  isSoldOut: z.boolean(), // is sold out
  users: z.union([z.array(z.string()), z.null()]),
  discount: z.union([DiscountSchema, z.null()]), // discount if present
  format: FinalFormatSchema, // format of the product
  selectedQuantity: z.number(), // quantity selected by the user
  roleType: z.string(),
  productStatus: z.string(),
});

export const DescriptionInputSchema = z.object({
  ita: z.string(),
  en: z.string(),
});

export const FinalFormatInpuSchema = z.object({
  unit: z.string(), // l, ml, kg, g, other
  unitPrice: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  unitQuantity: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  formatType: z.string(), // fusti
  formatQuantity: z.number(), // 2
  boxType: z.string(), // pedana
  boxTypeQuantity: z.number(), // 1
  boxWeight: z.number(), // 1
  description: DescriptionInputSchema, // string
});

export const FinalProductInputSchema = z.object({
  isThisYear: z.boolean(), // oil or olive from this campaign
  line: z.string(), //Natale, Simone, Cuscunà, etc
  price: z.number(), // price per unit
  certifications: z.array(z.string()), //bio, dop, etc
  attribute: z.array(z.string()), //fruttato, delicato, etc
  productId: z.string(), // unique id
  description: DescriptionInputSchema, // description of the product
  productType: z.string(), //oil, olive, etc
  sizeType: z.union([z.string(), z.null()]), // only for olive (00, 01, 02, 03, 04, 05)
  name: DescriptionInputSchema, // Natatle, Simone, Cuscunà, etc
  isNew: z.boolean(), // is new product
  isSoldOut: z.boolean(), // is sold out
  users: z.union([z.array(z.string()), z.null()]),
  discount: z.union([DiscountInputSchema, z.null()]), // discount if present
  format: FinalFormatInpuSchema, // format of the product
  selectedQuantity: z.number(), // quantity selected by the user
  roleType: z.string(),
  productStatus: z.string(),
});

export const FinalOrderSchema = z.object({
  __typename: z.literal("FinalOrder"),
  orderId: z.string(),
  userId: z.string(),
  status: z.union([
    z.literal("CREATED"),
    z.literal("ARRIVED"),
    z.literal("STARTED"),
    z.literal("READY"),
    z.literal("DELIVERING"),
    z.literal("DELIVERED"),
    z.literal("CANCELED"),
    z.literal("REJECTED"),
  ]),
  role: z.string(),
  orderName: z.string(),
  startDate: z.string(),
  estimateDate: z.string(),
  endDate: z.string(),
  tracking: z.array(
    z.object({
      __typename: z.literal("Tracking"),
      update: z.string(),
      // stase can be one of the following: "ARRIVED" | "STARTED" | "READY" | "DELIVERING" | "DELIVERED" | "CANCELED" | "REJECTED"
      status: z.union([
        z.literal("CREATED"),
        z.literal("ARRIVED"),
        z.literal("STARTED"),
        z.literal("READY"),
        z.literal("DELIVERING"),
        z.literal("DELIVERED"),
        z.literal("CANCELED"),
        z.literal("REJECTED"),
      ]),
    })
  ),
  info: z.object({
    __typename: z.literal("Description"),
    ita: z.string(),
    en: z.string(),
  }),
  payment: PaymentSchema,
  address: AddressSchema,
  products: z.array(FinalProductSchema),
});

export const FinalOrderInputSchema = z.object({
  orderId: z.string(),
  userId: z.string(),
  role: z.string(),
  status: z.union([
    z.literal("CREATED"),
    z.literal("ARRIVED"),
    z.literal("STARTED"),
    z.literal("READY"),
    z.literal("DELIVERING"),
    z.literal("DELIVERED"),
    z.literal("CANCELED"),
    z.literal("REJECTED"),
  ]),
  orderName: z.string(),
  startDate: z.string(),
  estimateDate: z.string(),
  endDate: z.string(),
  tracking: z.array(
    z.object({
      update: z.string(),
      // stase can be one of the following: "ARRIVED" | "STARTED" | "READY" | "DELIVERING" | "DELIVERED" | "CANCELED" | "REJECTED"
      status: z.union([
        z.literal("CREATED"),
        z.literal("ARRIVED"),
        z.literal("STARTED"),
        z.literal("READY"),
        z.literal("DELIVERING"),
        z.literal("DELIVERED"),
        z.literal("CANCELED"),
        z.literal("REJECTED"),
      ]),
    })
  ),
  info: z.object({
    ita: z.string(),
    en: z.string(),
  }),
  payment: PaymentInputSchema,
  address: AddressInputSchema,
  products: z.array(FinalProductInputSchema),
});

export const OrderInputSchema = z.object({
  orderId: z.string(),
  userId: z.string(),
  role: z.string(),
  orderName: z.string(),
  status: z.union([
    z.literal("CREATED"),
    z.literal("ARRIVED"),
    z.literal("STARTED"),
    z.literal("READY"),
    z.literal("DELIVERING"),
    z.literal("DELIVERED"),
    z.literal("CANCELED"),
    z.literal("REJECTED"),
  ]),
  startDate: z.string(),
  estimateDate: z.string(),
  endDate: z.string(),
  tracking: z.array(
    z.object({
      update: z.string(),
      // stase can be one of the following: "ARRIVED" | "STARTED" | "READY" | "DELIVERING" | "DELIVERED" | "CANCELED" | "REJECTED"
      status: z.union([
        z.literal("CREATED"),
        z.literal("ARRIVED"),
        z.literal("STARTED"),
        z.literal("READY"),
        z.literal("DELIVERING"),
        z.literal("DELIVERED"),
        z.literal("CANCELED"),
        z.literal("REJECTED"),
      ]),
    })
  ),
  info: z.string(),
  payment: PaymentInputSchema,
  address: AddressInputSchema,
  products: z.array(ProductInputSchema),
});

// Define the FinalOrderConnection type
export const FinalOrderConnectionSchema = z.object({
  __typename: z.literal("FinalOrderConnection"),
  items: z.array(FinalOrderSchema),
  nextToken: z.string().nullable(),
});
