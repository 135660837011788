import { deleteProduct, updateProduct } from "graphql/mutations";
import {
  DeleteProductMutationVariables,
  FinalProduct,
  FinalProductConnection,
  FinalProductInput,
  GetCatalogByUserPaginatedQueryVariables,
  GetProductByProductIdQueryVariables,
  UpdateProductMutationVariables,
} from "../../API";
import {
  getCatalogByUserPaginated,
  getProductByProductId,
} from "../../graphql/queries";
import {
  FinalProductConnectionSchema,
  FinalProductSchema,
} from "../../models/products";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import { uploadPhoto } from "utils/upload-photo";

export enum ImageResponse {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

interface CatalogApiInterface {
  getCatalogByUserPaginated: (i: {
    roleType: string;
    limit: number;
    nextToken?: string;
  }) => Promise<FinalProductConnection>;
  getProductByProductId: (i: { productId: string }) => Promise<FinalProduct>;
  updateProduct: (i: { product: FinalProductInput }) => Promise<FinalProduct>;
  uploadProductImage: (i: {
    image: string;
    productId: string;
    type: string;
  }) => Promise<ImageResponse>;
  deleteProduct: (i: {
    productId: string;
    roleType: string;
  }) => Promise<FinalProduct>;
}

const GetCatalogByUserIdQuerySchema = z.object({
  data: z.object({
    getCatalogByUserPaginated: FinalProductConnectionSchema,
  }),
});

const GetProductByPrdoductIdQuerySchema = z.object({
  data: z.object({
    getProductByProductId: FinalProductSchema,
  }),
});

const UpdateProductSchema = z.object({
  data: z.object({
    updateProduct: FinalProductSchema,
  }),
});

const DeleteProductSchema = z.object({
  data: z.object({
    deleteProduct: FinalProductSchema,
  }),
});

export class CatalogApi implements CatalogApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getCatalogByUserPaginated = async (i: {
    roleType: string;
    limit: number;
    nextToken?: string;
  }) => {
    try {
      // Fetch the data using your API
      const queryResult =
        await this.api.query<GetCatalogByUserPaginatedQueryVariables>({
          query: getCatalogByUserPaginated,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData = GetCatalogByUserIdQuerySchema.parse(queryResult);

      return validatedData.data.getCatalogByUserPaginated;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  getProductByProductId = async (i: { productId: string }) => {
    try {
      // Fetch the data using your API
      const queryResult =
        await this.api.query<GetProductByProductIdQueryVariables>({
          query: getProductByProductId,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData =
        GetProductByPrdoductIdQuerySchema.parse(queryResult);

      return validatedData.data.getProductByProductId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateProduct = async (i: { product: FinalProductInput }) => {
    try {
      // Fetch the data using your API
      const mutationResult =
        await this.api.query<UpdateProductMutationVariables>({
          query: updateProduct,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData = UpdateProductSchema.parse(mutationResult);

      return validatedData.data.updateProduct;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  deleteProduct = async (i: { productId: string; roleType: string }) => {
    try {
      // Fetch the data using your API
      const mutationResult =
        await this.api.query<DeleteProductMutationVariables>({
          query: deleteProduct,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData = DeleteProductSchema.parse(mutationResult);

      return validatedData.data.deleteProduct;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  uploadProductImage = async (i: {
    image: string;
    productId: string;
    type: string;
  }) => {
    try {
      await uploadPhoto(i.image, i.productId, `${i.type}/${i.productId}`);

      return ImageResponse.SUCCESS;
    } catch (error) {
      console.error(getAppError(error));
      return ImageResponse.ERROR;
    }
  };
}

export const catalogApi = new CatalogApi(api);
