import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Select as ChakraSelect,
  Text,
  Heading,
  Spinner,
  Stack,
  useToast,
  Flex,
  IconButton,
  SimpleGrid,
  useMediaQuery,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FinalHomeCard } from "API";
import InputField from "components/fields/InputField";
import { v4 as uuidv4 } from "uuid";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { Icons } from "images/svg";
import { FinalHomeCardInputSchema } from "models/banners";
import { bannerApi } from "services/banner-api/banner-api";
import Select from "react-select";

const styles = {
  option: (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: data.value,
      color: "#333333",
    };
  },
};

const paletteColors = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

const settings = {
  dots: true,
  arrows: false,
  fade: false,
  infinite: true,
  autoplay: false,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const sticker = {
  order: <Icons.Shipping width={40} height={40} />,
  newProduct: <Icons.New width={40} height={40} />,
  discount: <Icons.Discount width={40} height={40} />,
  interess: <Icons.Interess width={40} height={40} />,
  info: <Icons.Info width={40} height={40} />,
  mostPurchase: <Icons.Bestseller width={40} height={40} />,
};

type CardType = keyof typeof sticker;

const defaultCard: FinalHomeCard = {
  __typename: "FinalHomeCard",
  homeCardId: uuidv4(),
  priority: 0,
  type: "info",
  status: "DRAFT",
  name: { __typename: "Description", ita: "", en: "" },
  color: [paletteColors[0], paletteColors[1]],
  users: [],
  info: [
    {
      __typename: "FinalInfo",
      priority: 0,
      title: { __typename: "Description", ita: "", en: "" },
      description: { __typename: "Description", ita: "", en: "" },
      navigateTo: "",
      status: "DRAFT",
    },
  ],
};

export const NewBanner = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const [isSmScreen] = useMediaQuery("(max-width: 30em)");
  const [slider, setSlider] = useState<Slider | null>(null);
  const [card, setCard] = useState<FinalHomeCard>(defaultCard);
  const [lang, setLang] = useState<"ita" | "en">("ita");

  const { mutate: mutateUpdateBanner, isLoading: mutateIsLoading } =
    useMutation(
      ["updateHomeBanner", card.homeCardId],
      async () => {
        const finalCardInput = FinalHomeCardInputSchema.parse(card);

        const response = await bannerApi.updateBanner({
          homeCard: finalCardInput,
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });
          queryClient.invalidateQueries([
            "getCardByCardId",
            response.homeCardId,
          ]);
          navigate(`/banners/${response.homeCardId}`, { replace: true });
          return response;
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  if (mutateIsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  const cardFound = [
    "order",
    "newProduct",
    "discount",
    "interess",
    "info",
    "mostPurchase",
  ].find((cardType) => cardType === card.type);

  const cardType = (cardFound ?? "info") as CardType;

  const cardInfo = card.info ?? [];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={2} spacing={10}>
        <Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mb={5}
            mr={5}
          >
            <ChakraSelect
              value={lang}
              onChange={(e) => {
                setLang(e.target.value as "ita" | "en");
              }}
            >
              <option value="ita">ITA</option>
              <option value="en">EN</option>
            </ChakraSelect>
          </Box>

          <Accordion mt={2} allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Heading size="md">Banner</Heading>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Stack spacing={4}>
                  <Box>homeCardId: {card.homeCardId}</Box>

                  <Box display={"flex"}>
                    <Select
                      value={{ label: card.color[1], value: card.color[1] }}
                      menuPlacement="bottom"
                      options={paletteColors.map((color) => ({
                        label: color,
                        value: color,
                      }))}
                      onChange={(e) =>
                        e &&
                        setCard((prev) => ({
                          ...prev,
                          color: [prev.color[0], e.value],
                        }))
                      }
                      styles={{
                        option: styles.option,
                        control: (styles: any) => ({
                          ...styles,
                          backgroundColor: card.color[1],
                        }),
                      }}
                    />
                    <Select
                      value={{ label: card.color[0], value: card.color[0] }}
                      menuPlacement="bottom"
                      styles={{
                        option: styles.option,
                        control: (styles: any) => ({
                          ...styles,
                          backgroundColor: card.color[0],
                        }),
                      }}
                      options={paletteColors.map((color) => ({
                        label: color,
                        value: color,
                      }))}
                      onChange={(e) =>
                        e &&
                        setCard((prev) => ({
                          ...prev,
                          color: [e.value, prev.color[1]],
                        }))
                      }
                    />
                  </Box>

                  <InputField
                    id="priority"
                    label="Priority"
                    extra={<></>}
                    placeholder="Priority"
                    type="text"
                    mb="30px"
                    value={card.priority.toString()}
                    onChange={(e) => {
                      // must be a number
                      const value = e.target.value;
                      if (isNaN(Number(value))) {
                        return;
                      }
                      setCard((prev) => ({ ...prev, priority: Number(value) }));
                    }}
                  />

                  <ChakraSelect
                    value={card.type}
                    onChange={(e) =>
                      setCard((prev) => ({ ...prev, type: e.target.value }))
                    }
                  >
                    <option value="order">Order</option>
                    <option value="newProduct">New Product</option>
                    <option value="discount">Discount</option>
                    <option value="interess">Interess</option>
                    <option value="info">Info</option>
                    <option value="mostPurchase">Most Purchase</option>
                  </ChakraSelect>
                  <ChakraSelect
                    value={card.status}
                    onChange={(e) =>
                      setCard((prev) => ({ ...prev, status: e.target.value }))
                    }
                  >
                    <option value="DRAFT">DRAFT</option>
                    <option value="LIVE">LIVE</option>
                  </ChakraSelect>

                  <InputField
                    id="name-ita"
                    label="Name ita"
                    extra={<></>}
                    placeholder="Name ita"
                    type="text"
                    mb="30px"
                    value={card.name.ita}
                    onChange={(e) =>
                      setCard((prev) => ({
                        ...prev,
                        name: { ...prev.name, ita: e.target.value },
                      }))
                    }
                  />
                  <InputField
                    id="name-en"
                    label="Name en"
                    extra={<></>}
                    placeholder="Name en"
                    type="text"
                    mb="30px"
                    value={card.name.en}
                    onChange={(e) =>
                      setCard((prev) => ({
                        ...prev,
                        name: { ...prev.name, en: e.target.value },
                      }))
                    }
                  />

                  <Box>
                    users:{" "}
                    {card.users &&
                      card.users.map((attribute) => attribute).join(", ")}
                  </Box>

                  <InputField
                    id="users"
                    label="Users"
                    extra={<></>}
                    placeholder="Users"
                    type="text"
                    mb="30px"
                    value={card.users ? card.users.join(", ") : ""}
                    onChange={(e) =>
                      setCard((prev) => ({
                        ...prev,
                        users: e.target.value.split(",").map((u) => u.trim()),
                      }))
                    }
                  />
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Heading size="md">INFO</Heading>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Stack spacing={4}>
                  {cardInfo.map((info, index) => (
                    <Box key={index}>
                      <InputField
                        id="priority"
                        label="Priority"
                        extra={<></>}
                        placeholder="Priority"
                        type="text"
                        mb="30px"
                        value={info.priority.toString()}
                        onChange={(e) => {
                          // must be a number
                          const value = e.target.value;
                          if (isNaN(Number(value))) {
                            return;
                          }
                          setCard((prev) => ({
                            ...prev,
                            info:
                              prev.info &&
                              prev.info.map((i, iIndex) =>
                                iIndex === index
                                  ? { ...i, priority: Number(value) }
                                  : i
                              ),
                          }));
                        }}
                      />
                      <InputField
                        id="title-ita"
                        label="Title ita"
                        extra={<></>}
                        placeholder="Title ita"
                        type="text"
                        mb="30px"
                        value={info.title.ita}
                        onChange={(e) =>
                          setCard((prev) => ({
                            ...prev,
                            info:
                              prev.info &&
                              prev.info.map((i, iIndex) =>
                                iIndex === index
                                  ? {
                                      ...i,
                                      title: {
                                        ...i.title,
                                        ita: e.target.value,
                                      },
                                    }
                                  : i
                              ),
                          }))
                        }
                      />

                      <InputField
                        id="title-en"
                        label="Title en"
                        extra={<></>}
                        placeholder="Title en"
                        type="text"
                        mb="30px"
                        value={info.title.en}
                        onChange={(e) =>
                          setCard((prev) => ({
                            ...prev,
                            info:
                              prev.info &&
                              prev.info.map((i, iIndex) =>
                                iIndex === index
                                  ? {
                                      ...i,
                                      title: { ...i.title, en: e.target.value },
                                    }
                                  : i
                              ),
                          }))
                        }
                      />
                      <Box>description ita: {info.description.ita}</Box>
                      <InputField
                        id="description-ita"
                        label="Description ita"
                        extra={<></>}
                        placeholder="Description ita"
                        type="text"
                        mb="30px"
                        value={info.description.ita}
                        onChange={(e) =>
                          setCard((prev) => ({
                            ...prev,
                            info:
                              prev.info &&
                              prev.info.map((i, iIndex) =>
                                iIndex === index
                                  ? {
                                      ...i,
                                      description: {
                                        ...i.description,
                                        ita: e.target.value,
                                      },
                                    }
                                  : i
                              ),
                          }))
                        }
                      />

                      <InputField
                        id="description-en"
                        label="Description en"
                        extra={<></>}
                        placeholder="Description en"
                        type="text"
                        mb="30px"
                        value={info.description.en}
                        onChange={(e) =>
                          setCard((prev) => ({
                            ...prev,
                            info:
                              prev.info &&
                              prev.info.map((i, iIndex) =>
                                iIndex === index
                                  ? {
                                      ...i,
                                      description: {
                                        ...i.description,
                                        en: e.target.value,
                                      },
                                    }
                                  : i
                              ),
                          }))
                        }
                      />
                      <InputField
                        id="navigateTo"
                        label="Navigate to"
                        extra={<></>}
                        placeholder="Navigate to"
                        type="text"
                        mb="30px"
                        value={info.navigateTo ?? ""}
                        onChange={(e) =>
                          setCard((prev) => ({
                            ...prev,
                            info:
                              prev.info &&
                              prev.info.map((i, iIndex) =>
                                iIndex === index
                                  ? { ...i, navigateTo: e.target.value }
                                  : i
                              ),
                          }))
                        }
                      />

                      <ChakraSelect
                        value={info.status}
                        onChange={(e) =>
                          setCard((prev) => ({
                            ...prev,
                            info:
                              prev.info &&
                              prev.info.map((i, iIndex) =>
                                iIndex === index
                                  ? { ...i, status: e.target.value }
                                  : i
                              ),
                          }))
                        }
                      >
                        <option value="DRAFT">DRAFT</option>
                        <option value="LIVE">LIVE</option>
                      </ChakraSelect>
                    </Box>
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Stack mt={10} justifyContent="space-around">
            <Button
              onClick={() => {
                setCard((prev) => ({
                  ...prev,
                  info: [
                    ...(prev.info ?? []),
                    {
                      __typename: "FinalInfo",
                      priority: 0,
                      title: { __typename: "Description", ita: "", en: "" },
                      description: {
                        __typename: "Description",
                        ita: "",
                        en: "",
                      },
                      navigateTo: "",
                      status: "DRAFT",
                    },
                  ],
                }));
              }}
            >
              <Text>ADD INFO</Text>
            </Button>
            <Button onClick={() => mutateUpdateBanner()}>
              <Text>CREATE BANNER</Text>
            </Button>
          </Stack>
        </Box>
        <Box>
          <Box
            position={"relative"}
            width={"full"}
            overflow={"hidden"}
            w={isSmScreen ? "100%" : "80%"}
            mx={isSmScreen ? 0 : "auto"}
          >
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />

            <Box>
              <Box display="flex" mb={2}>
                {sticker[cardType]}
                <Heading ml={2}>{card.name[lang]}</Heading>
              </Box>
              <Slider {...settings} ref={(slider) => setSlider(slider)}>
                {cardInfo.map((cardInfo, index) => (
                  <Box key={index}>
                    <Flex
                      bg={`linear-gradient(to left, ${card.color[0]}, ${card.color[1]})`}
                      borderRadius={8}
                      overflow="hidden"
                      height={isSmScreen ? "200px" : "256px"}
                    >
                      {/* <Box
                  key={index}
                  height={isSmScreen ? "100px" : "100px"}
                  position="relative"
                  backgroundRepeat="no-repeat"
                  backgroundImage={`url(${card.image ?? defaultImage})`}
                  flex={1}
                /> */}

                      <Box
                        justifyContent="space-between"
                        display="flex"
                        flexDirection="column"
                        flex={2}
                        p={4}
                      >
                        <Stack spacing={3}>
                          <Heading textAlign="center">
                            {cardInfo.title[lang]}
                          </Heading>
                          <Text
                            noOfLines={
                              isSmScreen
                                ? cardInfo.navigateTo
                                  ? 2
                                  : 3
                                : cardInfo.navigateTo
                                ? 3
                                : 4
                            }
                          >
                            {cardInfo.description[lang]}
                          </Text>
                        </Stack>

                        {cardInfo.navigateTo && (
                          <Button
                            w="full"
                            justifySelf="flex-end"
                            onClick={() => navigate(cardInfo.navigateTo ?? "/")}
                          >
                            Scopri di più
                          </Button>
                        )}
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Slider>
              {cardInfo.length > 1 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mt={5}
                >
                  <IconButton
                    aria-label="left-arrow"
                    variant="ghost"
                    transform={"translate(0%, -50%)"}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}
                  >
                    <BiLeftArrowAlt size="40px" />
                  </IconButton>

                  <IconButton
                    aria-label="right-arrow"
                    variant="ghost"
                    transform={"translate(0%, -50%)"}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}
                  >
                    <BiRightArrowAlt size="40px" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
