import { Box, Button, SimpleGrid, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import BannerTable from "components/tables/BannerTable";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { bannerApi } from "services/banner-api/banner-api";

interface Banner {
  name: string;
  priority: string;
  status: string;
  button: string;
}

export const Banners = () => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const navigate = useNavigate();
  const { isError, isLoading } = useQuery(
    ["getHomeCards"],
    () => bannerApi.getBanners(),
    {
      onSuccess(data) {
        data.forEach((banner) => {
          setBanners((prev) => [
            ...prev,
            {
              name: banner.name.ita,
              priority: banner.priority.toString(), // Convert priority to string
              status: banner.status,
              button: banner.homeCardId,
            },
          ]);
        });
      },
    }
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Button mb={2} onClick={() => navigate("/banners/new-banner")}>
        ADD
      </Button>
      <SimpleGrid
        mb="20px"
        // columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <BannerTable tableData={banners} />
      </SimpleGrid>
    </Box>
  );
};
