import {
  Accordion,
  Text,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Select as ChakraSelect,
  Spinner,
  Stack,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FinalBox, FinalBoxInput } from "API";
import InputField from "components/fields/InputField";
import { FinalProductInputSchema } from "models/products";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { boxApi } from "services/boxes-api/box-api";
import { catalogApi } from "services/catalog-api/catalog-api";
import { v4 as uuidv4 } from "uuid";

const defaultBox: FinalBox = {
  __typename: "FinalBox",
  boxId: uuidv4(),
  bannerId: uuidv4(),
  status: "order",
  boxStatus: "DRAFT",
  title: { __typename: "Description", ita: "", en: "" },
  description: { __typename: "Description", ita: "", en: "" },
  products: [],
};

export const BoxPage = () => {
  const { boxId } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const [box, setBox] = React.useState<FinalBox>(defaultBox);
  const [product, setProduct] = React.useState<string>("");

  const { isLoading, isError, isSuccess } = useQuery(
    ["getBoxByBoxId", boxId],
    () => boxApi.getBox({ boxId: boxId || "" }),
    {
      enabled: !!boxId,
      onSuccess: (data) => {
        setBox(data);
        setProduct("");
      },
    }
  );

  const productBox = useQuery(
    ["getProductByProductId", product],
    () => catalogApi.getProductByProductId({ productId: product }),
    {
      enabled: false,
      onSuccess(data) {
        setBox((prev) => ({
          ...prev,
          products: [...(prev.products || []), data],
        }));
      },
      onError(error) {
        toast({
          title: "An error occurred.",
          description: "Unable to create the order.",
          status: "error",
        });
      },
    }
  );

  const { mutate: mutateUpdateBanner, isLoading: mutateBannerIsLoading } =
    useMutation(
      ["updateBox", boxId],
      async () => {
        const finalBoxInput: FinalBoxInput = {
          boxId: box.boxId,
          bannerId: box.bannerId,
          status: box.status,
          boxStatus: box.boxStatus,
          title: {
            ita: box.title.ita,
            en: box.title.en,
          },
          description: {
            ita: box.description.ita,
            en: box.description.en,
          },
          products: box.products
            ? box.products.map((product) =>
                FinalProductInputSchema.parse(product)
              )
            : [],
        };
        const response = await boxApi.updateBox({
          box: finalBoxInput,
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });
          queryClient.invalidateQueries(["getBoxByBoxId", boxId]);
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  const { mutate: mutateCloneBanner, isLoading: mutateCloneIsLoading } =
    useMutation(
      ["updateHomeBanner", boxId],
      async () => {
        const finalBoxInput: FinalBoxInput = {
          boxId: uuidv4(),
          bannerId: uuidv4(),
          status: box.status,
          boxStatus: "DRAFT",
          title: {
            ita: box.title.ita,
            en: box.title.en,
          },
          description: {
            ita: box.description.ita,
            en: box.description.en,
          },
          products: box.products
            ? box.products.map((product) =>
                FinalProductInputSchema.parse(product)
              )
            : [],
        };

        const response = await boxApi.updateBox({
          box: finalBoxInput,
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });
          // navigate and replace the current product with the new one
          queryClient.invalidateQueries(["getBoxByBoxId", response.boxId]);
          navigate(`/boxes/${response.boxId}`, { replace: true });
          return response;
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  const { mutate: mutateDeleteBanner, isLoading: mutateDeleteIsLoading } =
    useMutation(
      ["deleteBox", boxId],
      async () => {
        if (!box) {
          toast({
            title: "An error occurred.",
            description: "Unable to delete the product.",
            status: "error",
          });
          return;
        }
        const response = await boxApi.deleteBox({
          boxId: box.boxId,
          bannerId: box.bannerId,
        });
        return response;
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Order created.",
            description: "Your order has been updated.",
            status: "success",
          });

          navigate(`/boxes`, { replace: true });
          return response;
        },
        onError: (error) => {
          toast({
            title: "An error occurred.",
            description: "Unable to create the order.",
            status: "error",
          });
        },
      }
    );

  if (
    isLoading ||
    mutateBannerIsLoading ||
    mutateCloneIsLoading ||
    mutateDeleteIsLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  if (!isSuccess || !box) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Not found</div>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box>
        <Accordion mt={2} allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Heading size="md">Box</Heading>
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Stack spacing={4}>
                <Box>boxId: {box.boxId}</Box>
                <Box>bannerId: {box.bannerId}</Box>

                <InputField
                  id="title-ita"
                  label="Title ita"
                  extra={<></>}
                  placeholder="Title ita"
                  type="text"
                  mb="30px"
                  value={box.title.ita}
                  onChange={(e) => {
                    setBox((prev) => ({
                      ...prev,
                      title: { ...prev.title, ita: e.target.value },
                    }));
                  }}
                />

                <InputField
                  id="title-en"
                  label="Title en"
                  extra={<></>}
                  placeholder="Title en"
                  type="text"
                  mb="30px"
                  value={box.title.en}
                  onChange={(e) => {
                    setBox((prev) => ({
                      ...prev,
                      title: { ...prev.title, en: e.target.value },
                    }));
                  }}
                />

                <ChakraSelect
                  value={box.status}
                  onChange={(e) =>
                    setBox((prev) => ({ ...prev, status: e.target.value }))
                  }
                >
                  <option value="order">Order</option>
                  <option value="newProduct">New Product</option>
                  <option value="discount">Discount</option>
                  <option value="interess">Interess</option>
                  <option value="info">Info</option>
                  <option value="mostPurchase">Most Purchase</option>
                </ChakraSelect>
                <ChakraSelect
                  value={box.boxStatus}
                  onChange={(e) =>
                    setBox((prev) => ({ ...prev, boxStatus: e.target.value }))
                  }
                >
                  <option value="DRAFT">DRAFT</option>
                  <option value="LIVE">LIVE</option>
                </ChakraSelect>

                <InputField
                  id="description-ita"
                  label="Description ita"
                  extra={<></>}
                  placeholder="Description ita"
                  type="text"
                  mb="30px"
                  value={box.description.ita}
                  onChange={(e) =>
                    setBox((prev) => ({
                      ...prev,
                      description: { ...prev.description, ita: e.target.value },
                    }))
                  }
                />
                <InputField
                  id="description-en"
                  label="Description en"
                  extra={<></>}
                  placeholder="Description en"
                  type="text"
                  mb="30px"
                  value={box.description.en}
                  onChange={(e) =>
                    setBox((prev) => ({
                      ...prev,
                      description: { ...prev.description, en: e.target.value },
                    }))
                  }
                />
              </Stack>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Heading size="md">PRODUCTS</Heading>
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Stack spacing={4}>
                {box.products &&
                  box.products.map((info, index) => (
                    <HStack key={index}>
                      <Box>productId: {info.productId}</Box>
                      <Button
                        onClick={() => {
                          setBox((prev) => ({
                            ...prev,
                            products: prev.products?.filter(
                              (product) => product.productId !== info.productId
                            ),
                          }));
                        }}
                      >
                        <Text>DELETE PRODUCT</Text>
                      </Button>
                    </HStack>
                  ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Stack mt={10} justifyContent="space-around">
          <HStack>
            <InputField
              id="product"
              label="Product"
              extra={<></>}
              placeholder="Product"
              type="text"
              mb="30px"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
            />
            <Button onClick={() => productBox.refetch()}>
              <Text>ADD PRODUCT</Text>
            </Button>
          </HStack>
          <Button onClick={() => mutateUpdateBanner()}>
            <Text>UPDATE BOX</Text>
          </Button>
          <Button onClick={() => mutateCloneBanner()}>
            <Text>CLONE BOX</Text>
          </Button>
          <Button onClick={() => mutateDeleteBanner()}>
            <Text>DELETE BOX</Text>
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
