import { Box, SimpleGrid, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import UserTable from "components/tables/UserTable";
import React from "react";
import {
  UserAttributes,
  authApi,
  transformUserAttributes,
} from "services/auth-api/auth-api";

interface UserAttributesCustom extends UserAttributes {
  button: string;
}

export const Customers: React.FC = () => {
  const [users, setUsers] = React.useState<UserAttributesCustom[]>([]);

  const usersData = useQuery(["listAllUsers"], () => authApi.listAllUsers(), {
    onSuccess(data) {
      data.Users &&
        data.Users.forEach((user) => {
          if (user.Attributes) {
            const userAttributes = transformUserAttributes(user.Attributes);
            setUsers((prev) => [
              ...prev,
              {
                ...userAttributes,
                button: userAttributes.id,
              },
            ]);
          }
        });
    },
  });

  if (usersData.isInitialLoading && usersData.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (usersData.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <div>Error</div>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        // columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <UserTable tableData={users} />
      </SimpleGrid>
    </Box>
  );
};
