import { Spinner } from "@chakra-ui/react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { CheckRoutes } from "./CheckRoutes";
import { GlobalSidebar } from "components/sidebar/GlobalSidebar";
import UserReports from "views/admin/default";
import Marketplace from "views/admin/marketplace";
import DataTables from "views/admin/dataTables";
import Profile from "views/admin/profile";
import { Customers } from "pages/customers/customers";
import { Orders } from "pages/orders/orders";
import { Order } from "pages/orders/order";
import { Catalog } from "pages/catalog/catalog";
import { ProductPage } from "pages/catalog/product";
import { NewProduct } from "pages/catalog/newProduct";
import { Banners } from "pages/banners/banners";
import { Banner } from "pages/banners/banner";
import { NewBanner } from "pages/banners/newBanner";
import { AppMode } from "pages/appMode/appMode";
import { FFS } from "pages/ffs/ffs";
import { FF } from "pages/ffs/ff";
import { NewFF } from "pages/ffs/newff";
import { Newsletter } from "pages/newsletter/newsletter";
import { Boxes } from "pages/boxes/boxes";
import { BoxPage } from "pages/boxes/boxPage";
import { Invoicies } from "pages/invoicies/invoicies";
import { OrderForm } from "pages/invoicies/order-form";

export const AuthRoutes = () => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <GlobalSidebar>
        <Routes>
          <Route path="/home" element={<UserReports />} />
          <Route path="/jobs" element={<Marketplace />} />
          <Route path="/data-tables" element={<DataTables />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/*" element={<CheckRoutes />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/catalog/:productId" element={<ProductPage />} />
          <Route path="/catalog/new-product" element={<NewProduct />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:orderId" element={<Order />} />
          <Route path="/banners" element={<Banners />} />
          <Route path="/banners/:bannerId" element={<Banner />} />
          <Route path="/banners/new-banner" element={<NewBanner />} />
          <Route path="/app-mode" element={<AppMode />} />
          <Route path="/ffs" element={<FFS />} />
          <Route path="/ffs/:ffId" element={<FF />} />
          <Route path="/ffs/new-ff" element={<NewFF />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/boxes" element={<Boxes />} />
          <Route path="/boxes/:boxId" element={<BoxPage />} />
          <Route path="/invoicies" element={<Invoicies />} />
          <Route path="/invoicies/order-form" element={<OrderForm />} />
        </Routes>
      </GlobalSidebar>
    </React.Suspense>
  );
};
